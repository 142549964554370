import React, {useEffect, useState} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';

function Account() {
    useEffect(() => {
        userDetails();
    }, []);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleNewPassword = (e) => {
        setNewPasswordShown(!newPasswordShown);
    };
    const toggleConfirmPassword = (e) => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const [inputValues, setInputValue] = useState({
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [validation, setValidation] = useState({
        username : ''
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //username validation
        if (!inputValues.username.trim()) {
            errors.username = "Full Name is required";
          } else {
            errors.username = "";
          }
        setValidation(errors);
        if (errors.username === ''){
            return true;
        } else {
            return false;
        }
    };
    const [pwdValidation, setPwdValidation] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const checkPwdValidation = () => {
        let errors = JSON.parse(JSON.stringify(pwdValidation));
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;

        //password validation
        if (!inputValues.currentPassword) {
            errors.currentPassword = 'Password is required';
        } else if (!inputValues.currentPassword.match(passReg)) {
            errors.currentPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        } else {
            errors.currentPassword = '';
        }

        //new password validation
        const password = inputValues.newPassword;
        if (!password) {
            errors.newPassword = 'New Password is required';
        } else if (password === inputValues.currentPassword) {
            errors.newPassword = 'New Password should be different';
        } else if (!password.match(passReg)) {
            errors.newPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        } else {
            errors.newPassword = '';
        }

        //confirm password validation
        const cpassword = inputValues.confirmPassword;
        if (!cpassword) {
            errors.confirmPassword = 'Repeat New Password';
        } else if (cpassword !== password) {
            errors.confirmPassword = 'Both password should match';
        } else {
            errors.confirmPassword = '';
        }

        setPwdValidation(errors);
        if (errors.currentPassword === '' && errors.newPassword === '' && errors.confirmPassword === ''){
            return true;
        } else {
            return false;
        }
    };
    const [resultMessage, setResultMessage] = useState('');
    const [isAdded, setIsAdded] = useState(false);
    const [changePasswordMessage, setChangePasswordMessage] = useState('');
    const [uploadImage, setUploadImage] = useState();
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValue({...inputValues, [name]: value});
    }
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const uploadPhoto = event => {
        debugger;
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setUploadImage(fileData);
        }
    }
    const updateProfile = () => {
        const isSuccess = checkValidation();
        if (isSuccess){
            let updateProfileData = new FormData();
            updateProfileData.append('full_name', inputValues.username );
            if (uploadImage) {
                updateProfileData.append('image', uploadImage );
                setIsAdded(true);
            }
            setIsLoading(true);
            axios.put(`${appConstant.API_URL}/account_block/edit_profile`, updateProfileData, params)
                .then(res => {
                     setIsLoading(false);
                    if (res.data.status === 200) {
                        userDetails();
                        setResultMessage(res.data.message);
                    }else {
                        setResultMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    const changePassword = () => {
        const isSuccess = checkPwdValidation();
        if (isSuccess){
            setIsLoading(true);
            const changePasswordData = {
                current_password: inputValues.currentPassword,
                password: inputValues.newPassword,
                password_confirmation: inputValues.confirmPassword
            }
            axios.patch(`${appConstant.API_URL}/account_block/accounts/passwords`, changePasswordData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        setChangePasswordMessage(res.data.message);
                    }else {
                        setChangePasswordMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    const userDetails = () => {
        axios.get(`${appConstant.API_URL}/account_block/account_details`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setUserData(res?.data?.data);
                    // if(((res?.data?.data?.attributes?.image).substring(64,79) === '/assets/profile') || ((res?.data?.data?.attributes?.image).substring(64,79) === 'assets/profile-')) {
                    if(!res?.data?.data?.attributes?.image) {   
                        setIsAdded(false)
                        localStorage.setItem("image", '/images/userr.png')
                      } else {
                        setIsAdded(true)
                        localStorage.setItem("image", res?.data?.data?.attributes?.image)
                      }
                    //localStorage.setItem("image", res?.data?.data?.attributes?.image);
                    
                    const data = {
                        username: res?.data?.data?.attributes?.full_name,
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                    setInputValue(data);
                } else {
                }
            })
            .catch(error => {
            });
    }
    const deletePhoto = () => {
        setIsLoading(true);
        window.location.reload(true)
        axios.delete(`${appConstant.API_URL}/account_block/delete_profile_image`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setResultMessage(res.data.message);
                   // localStorage.setItem("image", '/images/userr.png');
                    userDetails();
                }else {
                    setResultMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                // props.changeLogin(true);
            });
    }
    return (
        <>
            <HireHeader/>
            <div className='filter-blank'></div>
            <div className='account home-content'>
                <div className='container'>
                    <div className='breadcrumbs'>
                        <div><Link to="/home/hire-equipment">Home / </Link> <strong>Account</strong></div>
                    </div>
                    <div className='title'>Account Details</div>
                    <ul className="nav nav-pills alyya-tabs">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="pill" href="#personal-info">Personal Information</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="pill" href="#change-pwd">Change Password</a>
                        </li>
                    </ul>
                    <div className="tab-content alyya-tabs-content">
                        <div className="tab-pane container active" id="personal-info">
                            <div className='account-content'>
                                <form className=' alyya-form'>
                                    <div className='row'>
                                        <div className='col-md-4 mb-5'>
                                            {uploadImage ? <img className='profile-pic' src={URL.createObjectURL(uploadImage)} alt="upload"/> : (userData?.attributes?.image ? <img className='profile-pic' src={userData?.attributes?.image} alt="upload"/> : <img className='profile-pic' src="/images/userr.png" alt="upload"/>)}
                                            {/* {uploadImage ? <img className='profile-pic' src={URL.createObjectURL(uploadImage)} alt="upload"/> : <img className='profile-pic' src={(userData?.attributes?.image)} alt="upload"/>} */}
                                            <div className="button-group">
                                                { isAdded && userData?.attributes?.image && <button className="border-only" type="button" onClick={()=>{deletePhoto()}}>Delete</button>}
                                                {/* {!userData?.attributes?.image && <button className="border-only" type="button" disabled={true} style={{cursor: 'auto', display: 'none'}}>Delete</button>} */}

                                                <div className="box asImage" onChange={uploadPhoto}>
                                                    <input type="file" id="uploadImage" name='uploadImage' className="inputfile" accept="image/*"/>
                                                </div>
                                                {isAdded ? <label htmlFor="uploadImage" className="filled-button text-center" style={{cursor: 'pointer'}}>Replace</label> : <label htmlFor="uploadImage" className="filled-button text-center" style={{cursor: 'pointer', width: '100%'}}>Add</label>}
                                                 {/* <label htmlFor="uploadImage" className="filled-button text-center" style={{cursor: 'pointer'}}>Replace</label> */}
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='account-form' style={{maxWidth: '430px'}}>
                                                {resultMessage && <p className='formErrors mb-2'>{resultMessage}</p>}
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='d-block style-label'>Full name </label><input className='style-input' type="text" placeholder='Full Name' name="username" value={inputValues.username} onChange={(e) => handleChange(e)} />
                                                </div>
                                                {validation.username && <p className='formErrors'>{validation.username}</p>}
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='d-block style-label'>Phone number </label><input disabled={true} className='style-input disabled' type="text" value={userData?.attributes?.phone_number} placeholder='Mobile Number'/>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <label className='d-block style-label'>Email ID </label><input disabled={true} className='style-input disabled' type="text" value={userData?.attributes?.email} placeholder='Email'/>
                                                </div>
                                                <div className='d-flex justify-content-end align-items-center mt-4'>
                                                    {isLoading ? <div className='position-relative'><button className="submit-btn m-0" disabled={true} type="button">Update</button> <img className='position-absolute' style={{"top" : "30%", "right" : "30%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :<button onClick={() => {updateProfile()}}  className="submit-btn m-0" type='button'>Update</button> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="tab-pane container" id="change-pwd">
                            <div className='account-content'>
                                <form className='account-form' style={{maxWidth: '500px'}}>
                                    {changePasswordMessage && <p className='formErrors mb-2'>{changePasswordMessage}</p>}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className='d-block style-label'>Current Password </label>
                                        <div className='position-relative'>
                                            <input className='style-input' type={passwordShown ? "text" : "password"} name='currentPassword' value={inputValues.currentPassword} placeholder='Password' onChange={(e) => handleChange(e)}/>
                                            <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={togglePassword}/>
                                        </div>

                                    </div>
                                    {pwdValidation.currentPassword && <p className='formErrors'>{pwdValidation.currentPassword}</p>}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className='d-block style-label'>New Password </label>
                                        <div className='position-relative'>
                                            <input className='style-input' type={newPasswordShown ? "text" : "password"} name="newPassword" value={inputValues.newPassword} placeholder='New Password' onChange={(e) => handleChange(e)}/>
                                            <img src={newPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleNewPassword}/>
                                        </div>
                                    </div>
                                    {pwdValidation.newPassword && <p className='formErrors'>{pwdValidation.newPassword}</p>}
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label className='d-block style-label'>Re enter New Password </label>
                                        <div className='position-relative'>
                                            <input className='style-input' name="confirmPassword" type={confirmPasswordShown ? "text" : "password"} value={inputValues.confirmPassword} placeholder='Re enter New Password' onChange={(e) => handleChange(e)}/>
                                            <img src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"} className="password-hide" alt="password" onClick={toggleConfirmPassword}/>
                                        </div>

                                    </div>
                                    {pwdValidation.confirmPassword && <p className='formErrors'>{pwdValidation.confirmPassword}</p>}
                                    <div className='d-flex justify-content-end align-items-center mt-4'>
                                        {isLoading ? <div className='position-relative'><button className="submit-btn m-0" disabled={true} type="button">Change password</button> <img className='position-absolute' style={{"top" : "30%", "right" : "16%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> : <button onClick={() => {changePassword()}}  className="submit-btn m-0" type="button">Change password</button> }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HireFooter/>
        </>
    )
};

export default Account;
