import React, {useState, useEffect} from 'react';
import { Link,useNavigate, useLocation } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import {YearsOfManufactures} from "../../utils"
import appConstant from '../../shared/constant/constant.json';
import "../../admin/css/registration.css"


function AddNewEquipment() {
    const nav = useNavigate();
    useEffect(()=>{
        getCategories();
        getLocations();
        filterList();
    }, []);
    const [categories, setCategories] = React.useState({});
    const [capacity, setCapacity] = React.useState({});
    const [locations, setLocations] = React.useState({});
    const [showImages, setShowImages] = React.useState(1);
    const [makers, setMakers] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modelData, setModelData] = useState(false);
    const showFiles = () => {
        setShowImages(parseInt(showImages)+1);
    }
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const getCategories = () => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/categories`, params)
            .then(res => {
                setCategories(res?.data?.data);
                if(res?.data?.data?.attributes?.sub_categories_name.length > 0){
                    setCapacity(res?.data?.data?.attributes?.sub_categories_name[0]);
                }


                // }else {
                // }
            })
            .catch(error => {
            });
    }
    const getLocations = () => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setLocations(res.data.locations);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const getSubLocations = (subCategoryId) => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setLocations(res.data.locations);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const getCapacity = (subCategoryId) => {
        axios.get(`${appConstant.API_URL}/bx_block_categories/capacities_of_sub_category?sub_category_id=${subCategoryId}`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setCapacity(res.data.capacities);
                     filterList(subCategoryId);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const filterList = (subCategoryId = '') => {
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/filter_data`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setMakers(res?.data?.maker);
                    if (subCategoryId && res?.data?.model[subCategoryId] !== undefined) {
                        setModelData(res?.data?.model);
                        setModels(res?.data?.model[subCategoryId])
                    }
                }else {
                }
            })
            .catch(error => {
            });
    }
    const [inputValues, setInputValue] = useState({
        product_name: "",
        category: "",
        product_model: "",
        year : "",
        capacity : "",
        product_maker: "",
        base_location: "",
        work_hours: "",
        video_url: "",
        description : "",
        price_per_day : "",
        price_per_week : "",
        price_per_month : ""
    });

    const [validation, setValidation] = useState({
        product_name: "",
        category: "",
        product_model: "",
        year : "",
        capacity : "",
        product_maker: "",
        base_location: "",
        work_hours: "",
        video_url: "",
        description : "",
        registration : "",
        insurance : "",
        TOV : "",
        operaterId : "",
        operaterCertificate : "",
        front : "",
        back : "",
        side1 : "",
        side2 : "",
        price_per_day : "",
        price_per_week : "",
        price_per_month : ""
    });

    const [checked, setChecked] = useState(false);

    const [registration, setRegistration] = useState({name: ''});
    const [insurance, setInsurance] = useState({name: ''});
    const [TOV, setTOV] = useState({name: ''});
    const [operaterId, setOperaterId] = useState({name: ''});
    const [operaterCertificate, setOperaterCertificate] = useState({name: ''});
    const [front, setFront] = useState();
    const [back, setBack] = useState();
    const [side1, setSide1] = useState();
    const [side2, setSide2] = useState();
    const [side3, setSide3] = useState();
    const [side4, setSide4] = useState();
    const [resultMessage, setResultMessage] = useState('');

    function handleChange(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
    }
    function fetchCapacity(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
        getCapacity(value);
        getSubLocations(value)
    }
    const uploadRegistration = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setRegistration(fileData);
        }
    }
    const uploadInsurance = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setInsurance(fileData);
        }
    }
    const uploadTOV = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setTOV(fileData);
        }
    }
    const uploadOperaterId = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setOperaterId(fileData);
        }
    }
    const uploadOperaterCertificate = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setOperaterCertificate(fileData);
        }
    }
    const uploadFront = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setFront(fileData);
        }
    };
    const uploadBack = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setBack(fileData);
        }
    };
    const uploadSide1 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide1(fileData);
        }
    };
    const uploadSide2 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide2(fileData);
        }
    };
    const uploadSide3 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide3(fileData);
        }
    };
    const uploadSide4 = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSide4(fileData);
        }
    };

    const checkDraftValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //product_name validation
        if (!inputValues.product_name.trim()) {
            errors.product_name = "Product Name is required";
        } else {
            errors.product_name = "";
        }
        if (!inputValues.category) {
            errors.category = "Category is required";
        } else {
            errors.category = "";
        }
        if (!front) {
            errors.front = "Please upload image";
        } else {
            errors.front = "";
        }
        setValidation(errors);
        if (errors.product_name === '' && errors.category === ''&& errors.front === ''){
            return true;
        } else {
            return false;
        }
    }

    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //product_name validation
        if (!inputValues.product_name.trim()) {
            errors.product_name = "Product Name is required";
        } else {
            errors.product_name = "";
        }
        //category validation
        if (!inputValues.category) {
            errors.category = "Category is required";
        } else {
            errors.category = "";
        }
       
        if (!inputValues.year.trim()) {
            errors.year = "Year is required";
        } else {
            errors.year = "";
        }
        //capacity validation
        if (!inputValues.capacity.trim()) {
            errors.capacity = "Capacity is required";
        } else {
            errors.capacity = "";
        }
        //product_maker validation
        if (!inputValues.product_maker.trim()) {
            errors.product_maker = "Product Maker is required";
        } else {
            errors.product_maker = "";
        }
        //base_location validation
        if (!inputValues.base_location.trim()) {
            errors.base_location = "Base Location is required";
        } else {
            errors.base_location = "";
        }
        //work_hours validation
        if (!inputValues.work_hours.trim()) {
            errors.work_hours = "Work Hours is required";
        } else {
            errors.work_hours = "";
        }
        
        if (!inputValues.description.trim()) {
            errors.description = "Description is required";
        } else {
            errors.description = "";
        }
        
        //registration validation
        if (!registration.name) {
            errors.registration = "Please upload document";
        } else {
            errors.registration = "";
        }
        //insurance validation
        if (!insurance.name) {
            errors.insurance = "Please upload document";
        } else {
            errors.insurance = "";
        }
        //TOV validation
        if (!TOV.name) {
            errors.TOV = "Please upload document";
        } else {
            errors.TOV = "";
        }
        if (!operaterId.name) {
            errors.operaterId = "Please upload document";
        } else {
            errors.operaterId = "";
        }
        //operaterCertificate validation
        if (!operaterCertificate.name) {
            errors.operaterCertificate = "Please upload document";
        } else {
            errors.operaterCertificate = "";
        }
        // //front validation
        if (!front) {
            errors.front = "Please upload image";
        } else {
            errors.front = "";
        }
      

        //price_per_day validation
        const numberCond = /^\d+(\.\d{1,2})?$/;

        if (!inputValues.price_per_day) {
            errors.price_per_day = "Required";
        } else if (!inputValues.price_per_day.match(numberCond)) {
            errors.price_per_day = "Please enter numeric value";
        } else if (inputValues.price_per_day < 1) {
            errors.price_per_day = "price should be greater than 0.";
        } else {
            errors.price_per_day = "";
        }

        //price_per_week validation
        if (!inputValues.price_per_week) {
            errors.price_per_week = "Required";
        } else if (!inputValues.price_per_week.match(numberCond)) {
            errors.price_per_week = "Please enter numeric value";
        } else if (inputValues.price_per_week < 1) {
            errors.price_per_week = "price should be greater than 0.";
        } else {
            errors.price_per_week = "";
        }

        //price_per_month validation
        if (!inputValues.price_per_month) {
            errors.price_per_month = "Required";
        } else if (!inputValues.price_per_month.match(numberCond)) {
            errors.price_per_month = "Please enter numeric value";
        } else if (inputValues.price_per_month < 1) {
            errors.price_per_month = "price should be greater than 0.";
        } else {
            errors.price_per_month = "";
        }

        setValidation(errors);
        // if (errors.product_name === '' && errors.category === '' && errors.product_model === '' && errors.year === '' && errors.capacity === '' && errors.product_maker === '' && errors.base_location === '' && errors.work_hours === '' && errors.video_url === '' && errors.front === '' && errors.insurance === '' && errors.operaterCertificate === '' && errors.operaterId === '' && errors.registration === '' && errors.TOV === '' && errors.description === '' && errors.checkedError === '' && errors.price_per_day === '' && errors.price_per_week === ''&& errors.price_per_month === ''){
            if (errors.product_name === '' && errors.category === '' && errors.product_model === '' && errors.year === '' && errors.capacity === '' && errors.product_maker === '' && errors.base_location === '' && errors.work_hours === '' && errors.video_url === '' && errors.front === '' && errors.insurance === '' && errors.operaterCertificate === '' && errors.operaterId === '' && errors.registration === '' && errors.TOV === '' && errors.description === '' && errors.price_per_day === '' && errors.price_per_week === ''&& errors.price_per_month === ''){
        return true;
        } else {
            return false;
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if (isSuccess){
            addEquipment('in_review');
        }
    };

    const addEquipment = (status) => {
        const isDraftValidation = checkDraftValidation();
        if(isDraftValidation){
            setIsLoading(true);
            let equipmentData = new FormData();
            if(inputValues.product_name){
                equipmentData.append('data[product_name]', inputValues.product_name.trim());
            }
            if(inputValues.category){
                equipmentData.append('data[sub_category_id]', inputValues.category);
            }
            if(inputValues.product_model){
                equipmentData.append('data[model_id]', inputValues.product_model);
            }
            if(inputValues.product_maker){
                equipmentData.append('data[maker_id]', inputValues.product_maker);
            }
            if(inputValues.capacity){
                equipmentData.append('data[capacity_id]', inputValues.capacity);
            }
            if(inputValues.base_location){
                equipmentData.append('data[base_location_id]', inputValues.base_location);
            }
            if(inputValues.description){
                equipmentData.append('data[description]', inputValues.description.trim());
            }
            if(inputValues.video_url){
                equipmentData.append('data[video_url]', inputValues.video_url.trim());
            }
            if(inputValues.year){
                equipmentData.append('data[year_of_manufature]', inputValues.year.trim());
            }
            if(inputValues.work_hours){
                equipmentData.append('data[no_of_working_hours_completed]', inputValues.work_hours.trim());

            }
            if(inputValues.price_per_day){
                equipmentData.append('data[price_per_day]', inputValues.price_per_day);
            }
            if(inputValues.price_per_week){
                equipmentData.append('data[price_per_week]', inputValues.price_per_week);
            }
            if(inputValues.price_per_month){
                equipmentData.append('data[price_per_month]', inputValues.price_per_month);
            }
            if(operaterId.name){
                equipmentData.append('data[operator_file]', operaterId);
            }
            if(front){
                equipmentData.append('data[front_image]', front);
            }
            if(back){
                equipmentData.append('data[back_image]', back);
            }
            if(side1){
                equipmentData.append('data[side_image1]', side1);
            }
            if(side2){
                equipmentData.append('data[side_image2]', side2);
            }
            if(side3){
                equipmentData.append('data[side_image3]', side3);
            }
            if(side4){
                equipmentData.append('data[side_image4]', side4);
            }
            if(insurance.name){
                equipmentData.append('data[upload_insurance]', insurance);
            }
            if(TOV.name){
                equipmentData.append('data[upload_tov_certificate]', TOV);
            }
            if(registration.name){
                equipmentData.append('data[upload_registartion]', registration);
            }
            if(operaterCertificate.name){
                equipmentData.append('data[operator_certificate]', operaterCertificate);
            }
            equipmentData.append('data[status]', status);
            const params = {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "token": localStorage.getItem('token')
                }
            };
            axios.post(`${appConstant.API_URL}/bx_block_catalogue/equipments`, equipmentData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        nav('/manage-equipment');
                    }else {
                        setResultMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }

    }
   
    return (
        <>
            <Header/>
            <div className='blank'></div>
            <div className='container py-4' key='addEquipment'>
                <div className='row'>
                    <div className='col-md-3'>
                        <Sidebar id="3"/>
                    </div>
                    <div className='col-md-9'>
                        <div className='rightSide'>
                            <div className='breadcrumbs'>
                                <div><Link to="/manage-equipment" className='breadLink' >Manage Equipment / </Link> <span className='active-page' >Add new Equipment</span></div>
                            </div>
                            <div className='content'>
                                <h3 className='title'>Add New Equipment</h3>
                                <form className='alyya-form' onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' value={inputValues.product_name} name="product_name" id="product_name" placeholder=' ' onChange={(e) => handleChange(e)}/>
                                            <label className="did-floating-label">Product Name</label>
                                            {validation.product_name && <p className='formErrors mb-2'>{validation.product_name}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="product_maker" className='style-input did-floating-select-bg did-floating-input' id="product_maker" value={inputValues.product_maker} onChange={(e) => handleChange(e)}>
                                                <option value='' key='category'>Select Maker</option>
                                                {makers.length > 0 && makers.map((result, i) =>
                                                    <>
                                                        <option value={result[0]} key={i}>{result[1]}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.product_maker && <label class="did-floating-label">Maker</label>}
                                            {validation.product_maker && <p className='formErrors mb-2'>{validation.product_maker}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="category" className='style-input did-floating-select-bg did-floating-input' id="category" value={inputValues.category} onChange={(e) => fetchCapacity(e)}>
                                                <option value='' key='category'>Select Category</option>
                                                {categories.length > 0 && categories.map((result, i) =>
                                                    <>
                                                        <optgroup label={result.attributes.category_name} key={i}>
                                                            {result.attributes.sub_categories_name.length > 0 && result.attributes.sub_categories_name.map((subCategory, m) =>
                                                                <><option value={subCategory[0]} key={m}>{subCategory[1]}</option>
                                                                </>
                                                            )}
                                                        </optgroup>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.category && <label class="did-floating-label">Category</label>}
                                            {validation.category && <p className='formErrors mb-2'>{validation.category}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="capacity" className='style-input did-floating-select-bg did-floating-input' id="capacity" value={inputValues.capacity} onChange={(e) => handleChange(e)}>
                                                <option value='' key='capacity'>Select Capacity</option>
                                                {capacity.length > 0 && capacity.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.value}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.capacity && <label class="did-floating-label">Capacity</label>}
                                            {validation.capacity && <p className='formErrors mb-2'>{validation.capacity}</p>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="product_model" className='style-input did-floating-select-bg did-floating-input' id="product_model" value={inputValues.product_model} onChange={(e) => handleChange(e)}>
                                                <option value='' key='model'>Select Model</option>
                                                {models.length > 0 && models.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.name}</option>
                                                    </>
                                                )}
                                            </select>
                                            {inputValues.product_model && <label class="did-floating-label">Model</label>}
                                        </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="year" className='style-input did-floating-select-bg did-floating-input' id="year" value={inputValues.year} onChange={(e) => handleChange(e)} data-testid="year-option">
                                                <option value="" key="Year of manufacture">Year of manufacture</option>
                                                {YearsOfManufactures()}
                                            </select>
                                            {inputValues.year && <label class="did-floating-label">Year of manufacture</label>}
                                            {validation.year && <p className='formErrors mb-2'>{validation.year}</p>}
                                       </div>
                                        </div>

                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <select name="base_location" className='style-input did-floating-select-bg did-floating-input' id="base_location" value={inputValues.base_location} onChange={(e) => handleChange(e)}>
                                                <option value='' key='capacity'>Select Location</option>
                                                {locations.length > 0 && locations.map((result, i) =>
                                                    <>
                                                        <option value={result.id} key={i}>{result.name}</option>
                                                    </>
                                                )}
                                            </select>
                                            {validation.base_location && <label class="did-floating-label">Location</label>}
                                            {validation.base_location && <p className='formErrors mb-2'>{validation.base_location}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-6'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' id="work_hours" name="work_hours" value={inputValues.work_hours} onChange={(e) => handleChange(e)} placeholder=' '/>
                                            <label className="did-floating-label">No. of working hours completed</label>
                                            {validation.work_hours && <p className='formErrors mb-2'>{validation.work_hours}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                        <div className="did-floating-label-content">
                                            <textarea name="description" value={inputValues.description} onChange={(e) => handleChange(e)} className='style-input did-floating-textarea' rows="5" placeholder=' '></textarea>
                                            <label className="did-floating-label">Add description</label>
                                            {validation.description && <p className='formErrors mb-2'>{validation.description}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage" onChange={uploadFront}>
                                                <input type="file" id="front" name='front' className="inputfile" accept="image/*"/>
                                                <label htmlFor="front">
                                                    {front ? <img src={URL.createObjectURL(front)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}
                                                    <span>Front</span></label>
                                                {validation.front && <p className='formErrors mb-2'>{validation.front}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage" onChange={uploadBack}>
                                                <input type="file" id="back" name='back' className="inputfile" accept="image/*"/>
                                                <label htmlFor="back">
                                                    {back ? <img src={URL.createObjectURL(back)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}
                                                    <span>Back</span></label>
                                                {validation.back && <p className='formErrors mb-2'>{validation.back}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide1}>
                                                <input type="file" id="side1" name='side1' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side1">
                                                    {side1 ? <img src={URL.createObjectURL(side1)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}
                                                    <span>Side 1</span></label>
                                                {validation.side1 && <p className='formErrors mb-2'>{validation.side1}</p>}
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide2}>
                                                <input type="file" id="side2" name='side2' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side2">
                                                    {side2 ? <img src={URL.createObjectURL(side2)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}<span>Side 2</span></label>
                                                {validation.side2 && <p className='formErrors mb-2'>{validation.side2}</p>}
                                            </div>
                                        </div>
                                        {showImages > 1 && <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide3}>
                                                <input type="file" id="side3" name='side3' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side3">
                                                    {side3 ? <img src={URL.createObjectURL(side3)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}<span>Side 3</span></label>
                                            </div>
                                        </div>
                                        }
                                        {showImages > 2 && <div className='col-sm-2'>
                                            <div className="box asImage"  onChange={uploadSide4}>
                                                <input type="file" id="side4" name='side4' className="inputfile" accept="image/*"/>
                                                <label htmlFor="side4">
                                                    {side4 ? <img src={URL.createObjectURL(side4)} alt="upload"/> : <img src="/images/image-upload.png" alt="upload"/>}<span>Side 4</span></label>
                                            </div>
                                        </div>
                                        }
                                        {showImages < 3 &&
                                        <div className='col-sm-2 d-flex justify-content-center align-items-start'>
                                            <div className="box mt-2" onClick={() => {showFiles()}}> <span className='add-input-file'>+</span></div>
                                        </div>
                                        }
                                        <div className='col-sm-12'>
                                        <div className="did-floating-label-content">
                                            <input type="text" className='style-input did-floating-input' id="video_url" name="video_url" value={inputValues.video_url} onChange={(e) => handleChange(e)} placeholder=' '/>
                                            <label className="did-floating-label">Video URL</label>
                                            {validation.video_url && <p className='formErrors mb-2'>{validation.video_url}</p>}
                                       </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Vehicle Documents Upload <span className='accepted-file' data-testid="valid-file-format">(pdf/docx/png/jpg/jpeg)</span></label>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadRegistration}>
                                                        <input type="file" id="registration" name='registration' className="inputfile" />
                                                        <label htmlFor="registration"><img src="/images/upload.png" alt="upload"/><span>{registration.name ? registration.name : 'Upload Registration'}</span></label>
                                                        {Object.keys(resultMessage).filter((items) => {
                                                                return (resultMessage[items].split(' ')[1] === 'registartion' )
                                                            }).map((item, index) => {
                                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    {validation.registration && <p className='formErrors mb-2'>{validation.registration}</p>}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadInsurance}>
                                                        <input type="file" id="insurance" name='insurance' className="inputfile" />
                                                        <label htmlFor="insurance"><img src="/images/upload.png" alt="upload"/><span>{insurance.name ? insurance.name : 'Upload Insurance'}</span></label>
                                                        {Object.keys(resultMessage).filter((items) => {
                                                                return (resultMessage[items].split(' ')[1] === 'insurance' )
                                                            }).map((item, index) => {
                                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    {validation.insurance && <p className='formErrors mb-2'>{validation.insurance}</p>}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadTOV}>
                                                        <input type="file" id="TOV" name='TOV' className="inputfile" />
                                                        <label htmlFor="TOV"><img src="/images/upload.png" alt="upload"/><span>{TOV.name ? TOV.name : 'Upload TOV Certificate'}</span></label>
                                                        {Object.keys(resultMessage).filter((items) => {
                                                                return (resultMessage[items].split(' ')[1] === 'tov' )
                                                            }).map((item, index) => {
                                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    {validation.TOV && <p className='formErrors mb-2'>{validation.TOV}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Operator Documents Upload</label>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadOperaterId}>
                                                        <input type="file" id="operaterId" name='operaterId' className="inputfile" />
                                                        <label htmlFor="operaterId"><img src="/images/upload.png" alt="upload"/><span>{operaterId.name ? operaterId.name : 'Operater ID'}</span></label>
                                                        {Object.keys(resultMessage).filter((items) => {
                                                                return (resultMessage[items].split(' ')[1] === 'file' )
                                                            }).map((item, index) => {
                                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    {validation.operaterId && <p className='formErrors mb-2'>{validation.operaterId}</p>}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="box" onChange={uploadOperaterCertificate}>
                                                        <input type="file" id="operaterCertificate" name='operaterCertificate' className="inputfile" />
                                                        <label htmlFor="operaterCertificate"><img src="/images/upload.png" alt="upload"/><span>{operaterCertificate.name ? operaterCertificate.name : 'Operater Certificate'}</span></label>
                                                        {Object.keys(resultMessage).filter((items) => {
                                                                return (resultMessage[items].split(' ')[1] === 'certificate' )
                                                            }).map((item, index) => {
                                                                return resultMessage && <p className='formErrors p-2'>{resultMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    {validation.operaterCertificate && <p className='formErrors mb-2'>{validation.operaterCertificate}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className='style-label'>Define Price in SAR (to hire instantly by customer)</label>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text" className='style-input did-floating-input' value={inputValues.price_per_day} name="price_per_day" id="price_per_day" placeholder=' ' onChange={(e) => handleChange(e)}/>
                                                    <label className="did-floating-label">Price per day</label>
                                                    {validation.price_per_day && <p className='formErrors mb-2'>{validation.price_per_day}</p>}
                                                </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text" className='style-input did-floating-input' value={inputValues.price_per_week} name="price_per_week" id="price_per_week" placeholder=' ' onChange={(e) => handleChange(e)}/>
                                                    <label className="did-floating-label">Price per Week</label>
                                                    {validation.price_per_week && <p className='formErrors mb-2'>{validation.price_per_week}</p>}
                                               </div>
                                                </div>
                                                <div className='col-sm-4'>
                                                <div className="did-floating-label-content">
                                                    <input type="text" className='style-input did-floating-input' value={inputValues.price_per_} name="price_per_month" id="price_per_month" placeholder=' ' onChange={(e) => handleChange(e)}/>
                                                    <label className="did-floating-label">Price per Month</label>
                                                    {validation.price_per_month && <p className='formErrors mb-2'>{validation.price_per_month}</p>}
                                               </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-12'>
                                            <label className="checkbox-style my-3" style={{visibility: 'hidden'}}>
                                                <span className="small">Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper condimentum, a senectus vivamus magnis.</span>
                                                <input type="checkbox" checked={true} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='btn-groups'>
                                                {isLoading ? <div className='position-relative'><button className="link-buttons" disabled={true} type="button">Save</button> <img className='position-absolute' style={{"top" : "20%", "right" : "11%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                                    <button type="button" className='link-buttons' onClick={() => {addEquipment('in_draft')}}>Save</button>
                                                }
                                            <Link to={'/manage-equipment'} className='link-buttons' data-testid="cancel-btn">Cancel</Link>
                                            {isLoading ? <div className='position-relative'><button className="submit-buttons" disabled={true} type="button">Submit for Review</button> <img className='position-absolute' style={{"top" : "30%", "right" : "4%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                                <button type="submit" className='submit-buttons'>Submit for Review</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AddNewEquipment;