import React, {useEffect, useState} from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import axios from 'axios';
import appConstant from '../../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../../shared/Loader';

function Orders() {
    const [isLoading, setIsLoading] = useState(true);
    const [paginationPages, setPaginationPages] = React.useState([]);
    useEffect(() => {
        orderlist(1);
    }, []);
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const [orders, setOrders] = React.useState({});
    const orderlist = (page) => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/seller_orders?page=${page}&limit=20`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setOrders(res.data);
                    const row = [];
                    for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
                        row.push(i);
                    }
                    setPaginationPages(row);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    return (
        <>
            <Header/>
            <div className='blank'></div>

            <div className='container py-4'>
                <div className='row'>
                    <div className='col-md-3'>
                        <Sidebar id="5"/>
                    </div>
                    <div className='col-md-9'>
                        {isLoading ? <LoadingSpinner/> :
                            <div className='rightSide'>
                                <div className='breadcrumbs'>
                                    <span className='active-page'>Orders</span>
                                </div>
                                <div className="table-responsive alyya-table">
                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            {/*<th><input type="checkbox"/></th>*/}
                                            <th>Order ID</th>
                                            <th className='min-150'>Order Date</th>
                                            <th className='min-250'>Customer</th>
                                            <th className='min-180'>STATUS</th>
                                            <th className='min-150'>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {orders.data?.length > 0 && orders.data?.map((result, i) =>
                                            <>
                                                <tr key={i}>
                                                    {/*<td><input type="checkbox"/></td>*/}
                                                    <td>{result?.order_number}</td>
                                                    <td className='min-150'>{Moment(result?.ordered_date).format('DD-MM-YYYY')}</td>
                                                    <td className='min-250'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src={result?.image} className="profile-pic" alt=""/>
                                                            <div>
                                                                <strong className='d-block'>{result?.customer_name}</strong>
                                                                <span className='d-block'>{result?.customer_email}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='min-180'>
                                                        {result?.payment_status === 'PAID' &&
                                                            <span className='paid'><span className='entity'>&#10004;</span> paid</span>}
                                                        {result?.payment_status === 'UNPAID' &&
                                                            <span className='refund'><span className='entity'>&#11170;</span> Unpaid</span>}
                                                        {result?.payment_status === 'REFUND' &&
                                                            <span className='refund'><span className='entity'>&#11170;</span> Refunded</span>}
                                                        {/*{result?.payment_status === 'CANCELLED' && <span className='cancel'><span className='entity'>&#x1F5D9;</span> Cancelled</span>}*/}
                                                    </td>
                                                    <td className='min-150'>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <span className='links'>Download</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {orders?.data?.length === 0 && <tr>
                                            <td colSpan='5'>Not Found</td>
                                        </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <ul className="pagination justify-content-between alyya-pagination">
                                    {orders.current_page === 1 &&
                                        <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                    {orders.current_page !== 1 && <li className="page-item" onClick={() => {
                                        orderlist(orders.current_page - 1)
                                    }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                                    <div className='d-flex'>
                                        {paginationPages?.length > 0 && paginationPages?.map((result, i) =>
                                            <>
                                                {result === orders.current_page &&
                                                    <li className="page-item"><a className="page-link active">{result}</a></li>}
                                                {result !== orders.current_page &&
                                                    <li className="page-item"><a className="page-link" onClick={() => {
                                                        orderlist(result)
                                                    }}>{result}</a></li>}

                                            </>
                                        )}
                                    </div>

                                    {orders.current_page === orders.total_pages &&
                                        <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                    {orders.current_page !== orders.total_pages && <li className="page-item" onClick={() => {
                                        orderlist(orders.current_page + 1)
                                    }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default Orders;
