import React from 'react';
import {Link} from 'react-router-dom';

function Sidebar(props) {
  return (
    <>
        <aside className='sidebar'>
            <nav className="navbar">
                <ul className="navbar-nav">
                <li className="nav-item">                    
                    <Link to="/dashboard" className={"nav-link " + (props.id === "1" ? 'active' : '')}>Dashboard</Link>
                </li>
                <li className="nav-item">
                    <Link to="/quote-request" className={"nav-link " + (props.id === "2" ? 'active' : '')}>Manage Orders</Link>
                </li>
                <li className="nav-item" data-testid="manage-equipment">
                    <Link to="/manage-equipment"  className={"nav-link " + (props.id === "3" ? 'active' : '')} >Manage Equipment</Link>
                </li>
                <li className="nav-item">
                    <Link to="/subscriptions" className={"nav-link " + (props.id === "4" ? 'active' : '')}>Subscription</Link>
                </li>
                <li className="nav-item">
                    <Link to="/orders" className={"nav-link " + (props.id === "5" ? 'active' : '')}>Orders</Link>
                </li>
                </ul>
                <div className='nav-foot'>
                <div className='copyright'>© {new Date().getFullYear()} Alyya</div>
                <Link to="/help" className="quick-links">Contact us</Link>
                <Link to="/app-about" className="quick-links">About us</Link>
                <Link to="/app-version" className="quick-links">Version 1.32.34</Link>          
                </div>
            </nav>
        </aside>
    </>
  )
};

export default Sidebar;