import React, {useEffect, useState} from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';
import "../admin/css/registration.css"

function AdminAccount() {
    useEffect(() => {
        userDetails();
        VendorCompanyData();
    }, []);
    const [passwordShown, setPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [companyMessage, setCompanyMessage] = useState('')
    const [isAdded, setIsAdded] = useState(false);
    const [fileValidation, setFileValidation] = useState({
        gosi: '',
        saudization: '',
        zakat: '',
        company: ''
    });

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleNewPassword = (e) => {
        setNewPasswordShown(!newPasswordShown);
    };
    const toggleConfirmPassword = (e) => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const [inputValues, setInputValue] = useState({
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [validation, setValidation] = useState({
        username : ''
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));

        //username validation
        if (!inputValues.username.trim()) {
            errors.username = "Full Name is required";
          } else {
            errors.username = "";
          }
        setValidation(errors);
        if (errors.username === ''){
            return true;
        } else {
            return false;
        }
    };
    const [pwdValidation, setPwdValidation] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const checkPwdValidation = () => {
        let errors = JSON.parse(JSON.stringify(pwdValidation));
        // const num = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/;
        const passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6})/;

        //password validation
        if (!inputValues.currentPassword) {
            errors.currentPassword = 'Password is required';
        } else if (!inputValues.currentPassword.match(passReg)) {
            errors.currentPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        } else {
            errors.currentPassword = '';
        }

        //new password validation
        const password = inputValues.newPassword;
        if (!password) {
            errors.newPassword = 'New Password is required';
        } else if (password === inputValues.currentPassword) {
            errors.newPassword = 'New Password should be different';
        } else if (!password.match(passReg)) {
            errors.newPassword = "Password must be at least 6 characters and must include at least one upper case letter, one lower case letter, one special character and one numeric digit.";
        } else {
            errors.newPassword = '';
        }

        //confirm password validation
        const cpassword = inputValues.confirmPassword;
        if (!cpassword) {
            errors.confirmPassword = 'Repeat New Password';
        } else if (cpassword !== password) {
            errors.confirmPassword = 'Both password should match';
        } else {
            errors.confirmPassword = '';
        }

        setPwdValidation(errors);
        if (errors.currentPassword === '' && errors.newPassword === '' && errors.confirmPassword === ''){
            return true;
        } else {
            return false;
        }
    };
    const [resultMessage, setResultMessage] = useState('');
    const [changePasswordMessage, setChangePasswordMessage] = useState('');
    const [uploadImage, setUploadImage] = useState();
    const handleChange = (e) => {
        const {name, value} = e.target;
        setInputValue({...inputValues, [name]: value});
    }
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const uploadPhoto = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setUploadImage(fileData);
        }
    }
    const updateProfile = () => {
        setIsLoading(true);
        const isSuccess = checkValidation();
        if (isSuccess){
            let updateProfileData = new FormData();
            updateProfileData.append('full_name', inputValues.username );
            if (uploadImage) {
                updateProfileData.append('image', uploadImage );
                setIsAdded(true);
            }
            axios.put(`${appConstant.API_URL}/account_block/edit_profile`, updateProfileData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        // localStorage.setItem("image", res?.data?.data?.attributes?.image);
                        // const data = {
                        //     username: res?.data?.data?.attributes?.full_name,
                        //     currentPassword: '',
                        //     newPassword: '',
                        //     confirmPassword: ''
                        // }
                        // setInputValue(data);
                        userDetails();
                        setResultMessage(res.data.message);
                    }else {
                        setResultMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    const changePassword = () => {
        const isSuccess = checkPwdValidation();
        if (isSuccess){
            setIsLoading(true);
            const changePasswordData = {
                current_password: inputValues.currentPassword,
                password: inputValues.newPassword,
                password_confirmation: inputValues.confirmPassword
            }
            axios.patch(`${appConstant.API_URL}/account_block/accounts/passwords`, changePasswordData, params)
                .then(res => {
                    setIsLoading(false);
                    if (res.data.status === 200) {
                        setChangePasswordMessage(res.data.message);
                    }else {
                        setChangePasswordMessage(res.data.message);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    // props.changeLogin(true);
                });
        }
    }
    const userDetails = () => {
        axios.get(`${appConstant.API_URL}/account_block/account_details`, params)
            .then(res => {
                if (res.data.status === 200) {
                    setUserData(res?.data?.data);
                    if(!res?.data?.data?.attributes?.image) {   
                        setIsAdded(false)
                        localStorage.setItem("image", '/images/userr.png')
                      } else {
                        setIsAdded(true)
                        localStorage.setItem("image", res?.data?.data?.attributes?.image)
                      }
                    localStorage.setItem('full_name', res?.data?.data?.attributes?.full_name);
                    //localStorage.setItem("image", res?.data?.data?.attributes?.image);
                    const data = {
                        username: res?.data?.data?.attributes?.full_name,
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                    setInputValue(data);
                } else {
                }
            })
            .catch(error => {
            });
    }
    const deletePhoto = () => {
        setIsLoading(true);
        window.location.reload(true)
        axios.delete(`${appConstant.API_URL}/account_block/delete_profile_image`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setResultMessage(res.data.message);
                    // localStorage.setItem("image", '/images/login_banner.png');
                    userDetails();
                }else {
                    setResultMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                // props.changeLogin(true);
            });
    }


    const [organization, setOrganization] = useState({
        organization_name: "",
        contact_person: "",
        contact_number: "",
        email: "",
        number_of_employees: "",
        vat: "",
        registration: "",
        address: "",
        inception: "",
        description: ""
    });
    const [orgValidation, setOrgValidation] = useState({
        organization_name: "",
        contact_person: "",
        contact_number: "",
        email: "",
        number_of_employees: "",
        vat: "",
        registration: "",
        address: "",
        inception: "",
        description: "",
        GOSI: "",
        saudization: "",
        zakat: "",
        company: ""
    });
    const [GOSI, setGOSI] = useState({name: ''});
    const [saudization, setSaudization] = useState({name: ''});
    const [zakat, setZakat] = useState({name: ''});
    const [company, setCompany] = useState({name: ''});
    const [checked, setChecked] = useState(false);
    const [companyDetails, setCompanyDetails] = useState(false);


    function changeValue(e) {
        const { name, value } = e.target;
        setOrganization({ ...organization, [name]: value });
    }
    const uploadGOSI = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setGOSI(fileData);
        }
    }
    const uploadSaudization = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setSaudization(fileData);
        }
    }
    const uploadZakat = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setZakat(fileData);
        }
    }
    const uploadCompany = event => {
        const fileData = event.target.files[0];
        if(fileData !== undefined) {
            setCompany(fileData);
        }
    }
    const checkOrgValidation = () => {
        let errors = JSON.parse(JSON.stringify(orgValidation));
        //organization_name validation
        var regName = /^(?! )[\w ]+$/;
        if (!organization.organization_name) {
            errors.organization_name = "Organization Name is required";
        } else if (!organization.organization_name.match(regName)) {

            errors.organization_name = "Organization Name is not valid";
        }else if(organization.organization_name.length<2){
            errors.organization_name = "Organization Character minimum length is 2";
        }

        else {
         errors.organization_name = "";
        }
        //contact_person validation
        if (!organization.contact_person) {
            errors.contact_person = "Contact Person is required";
        } else if (!organization.contact_person.match(regName)) {
            errors.contact_person = "Contact Person is not valid";
        } else if(organization.contact_person.length<2){
        errors.contact_person = "Contact Person Character minimum length is 2";
        }
        else {
            errors.contact_person = "";
        }
        //contact_number validation
        const numberCond = /^(?:\+966|0)?\d{9}$/;
        if (!organization.contact_number) {
            errors.contact_number = "Contact Number is required";
        }
         else if (!organization.contact_number.toString().match(numberCond)) {
            errors.contact_number = "Please enter 9 digit or 0 followed by 9 digit or +966 followed by 9 digit";
        }  else if (organization.contact_number.length===9 && organization.contact_number.toString().charAt(0)=== '0') {  
            errors.contact_number = 'Please enter valid number';
          }
         else {
            errors.contact_number = "";
        }
        //email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+@[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9-]{2,})*$/;
        if (!organization.email) {
            errors.email = "Email is required";
        } else if (!organization.email.match(emailCond)) {
            errors.email =`Please enter valid Email address`;
        }
         else {
            errors.email = "";
        }
        //number_of_employees validation
        if (!organization.number_of_employees) {
            errors.number_of_employees = "No. of Employees is required";
        }
        else if(isNaN(organization.number_of_employees)){
            setCompanyMessage("")
            errors.number_of_employees = "Number of Employee should be numeric"

        } else {
            errors.number_of_employees = "";
        }
        //vat validation
       const vatCond = /^(?!0{15})\d{15}$/
        if (!organization.vat) {
            errors.vat = "Vat is required";
        } else if (isNaN(organization.vat)){
            errors.vat = "Vat should be numeric"
        }
        else if (!organization.vat.match(vatCond)){
            errors.vat = "Please enter 15 digit numeric"
        }
        else {
            errors.vat = "";
        }
        //registration validation
        const registrationCond= /^(?!0{10})\d{10}$/
        if (!organization.registration) {
            errors.registration = "Registration is required";
        } 
        else if (isNaN(organization.registration)){
            errors.registration = "Registration should be numeric"
        }else if (!organization.registration.match(registrationCond)){
           errors.registration = "Please enter 10 digit numeric"
        }
        else {
            errors.registration = "";
        }
        //address validation
        if (!organization.address) {
            errors.address = "Address is required";
        } else {
            errors.address = "";
        }
        //inception validation
        if (organization.inception === '') {
            errors.inception = "Inception is required";
        } else {
            errors.inception = "";
        }
        //description validation
        
        // terms checked errors
        // if (!checked) {
        //     errors.checkedError = 'Please select';
        // } else {
        //     errors.checkedError = '';
        // }
        //GOSI validation
        if (!GOSI.name && fileValidation.gosi === '') {
            errors.GOSI = "Please upload document";
        } else {
            errors.GOSI = "";
        }
        //saudization validation
        if (!saudization.name && fileValidation.saudization === '') {
            errors.saudization = "Please upload document";
        } else {
            errors.saudization = "";
        }
        //zakat validation
        if (!zakat.name && fileValidation.zakat === '') {
            errors.zakat = "Please upload document";
        } else {
            errors.zakat = "";
        }
        //company validation
        if (!company.name && fileValidation.company === '') {
            errors.company = "Please upload document";
        } else {
            errors.company = "";
        }
       
        setOrgValidation(errors);
        if (errors.organization_name === '' && errors.contact_person === '' && errors.contact_number === '' && errors.email === '' && errors.number_of_employees === '' && errors.vat === '' && errors.registration === '' && errors.address === '' && errors.inception === '' && errors.description === '' && errors.GOSI === '' && errors.saudization === ''  && errors.zakat === '' && errors.company === ''){
            return true;
        } else {
            return false;
        }

        
    };
    const handleSubmit = (e) => {
        e.preventDefault();
      
        const isSuccess = checkOrgValidation();
        if (isSuccess){
            updateCompanyDetails('in_review');
        }
    };

    const updateCompanyDetails = (status='in_review') => {
        
        setIsLoading(true);
        let companyData = new FormData();
        if(organization.organization_name){
            companyData.append('data[organisation_name]', organization.organization_name);
        }
        if(organization.contact_person){
            companyData.append('data[contact_person]', organization.contact_person);
        }
        if(organization.contact_number){
            companyData.append('data[contact_number]', organization.contact_number);
        }
        if(organization.email){
            companyData.append('data[email]', organization.email);
        }
        if(organization.number_of_employees){
            companyData.append('data[number_of_employees]', organization.number_of_employees);
        }
        if(organization.vat){
            companyData.append('data[vat]', organization.vat);
        }
        if(organization.registration){
            companyData.append('data[registration]', organization.registration);
        }
        if(organization.address){
            companyData.append('data[address]', organization.address);
        }
        if(organization.description){
            companyData.append('data[description]', organization.description);
        }
        if(organization.inception){
            companyData.append('data[year_of_inception]', organization.inception);
        }
        if(GOSI.name){
            companyData.append('data[gosi_certificate]', GOSI);
        }
        if(saudization.name){
            companyData.append('data[saudization_certificate]', saudization);
        }
        if(zakat.name){
            companyData.append('data[zakat_certificate]', zakat);
        }
        if(company.name){
            companyData.append('data[company_registration]', company);
        }
        companyData.append('data[status]', status);
        const params = {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "token": localStorage.getItem('token')
            }
        };
       
        axios.post(`${appConstant.API_URL}/bx_block_profile/registrations`, companyData, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    VendorCompanyData();
                }else {
                    setCompanyMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);

                // props.changeLogin(true);
        });
    }
    const VendorCompanyData = () => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_profile/show_registration`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setCompanyDetails(res?.data?.data);
                    setCompanyMessage("")

                    let regeX=/^(?:966|0)?\d{9}$/
                    let phoneNumber=""
                    if(regeX.test(res?.data?.data?.attributes?.contact_number)){
                        if(res?.data?.data?.attributes?.contact_number.toString().startsWith(966)){
                          phoneNumber= "+" + res?.data?.data?.attributes?.contact_number
                        }else{
                          phoneNumber = res?.data?.data?.attributes?.contact_number
                        }
                      }
                    const data = {
                        organization_name: res?.data?.data?.attributes?.organisation_name,
                        contact_person: res?.data?.data?.attributes?.contact_person,
                        contact_number: phoneNumber,
                        email: res?.data?.data?.attributes?.email,
                        number_of_employees: res?.data?.data?.attributes?.number_of_employees,
                        vat: res?.data?.data?.attributes?.vat,
                        registration: res?.data?.data?.attributes?.registration,
                        address: res?.data?.data?.attributes?.address,
                        inception: res?.data?.data?.attributes?.year_of_inception,
                        description: res?.data?.data?.attributes?.description
                    }
                    setOrganization(data);
                    const fileData = {
                        gosi: res?.data?.data?.attributes?.gosi_certificate ? res?.data?.data?.attributes?.gosi_certificate : '',
                        saudization: res?.data?.data?.attributes?.saudization_certificate ? res?.data?.data?.attributes?.saudization_certificate : '',
                        zakat: res?.data?.data?.attributes?.zakat_certificate ? res?.data?.data?.attributes?.zakat_certificate : '',
                        company: res?.data?.data?.attributes?.company_registration ? res?.data?.data?.attributes?.company_registration : ''
                    }
                    setFileValidation(fileData);
                    YearsOfManufactures(res?.data?.data?.attributes?.year_of_inception);
                }else {
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
    const currentYear = (new Date()).getFullYear();
    const YearsOfManufactures = (defaultYear = '') => {
        const row = [];
        for (var i = 1900; i <= parseInt(currentYear); i++) {
            if(defaultYear && parseInt(defaultYear) === i){
                row.push(<option value={i} key={i} selected='selected'>{i}</option> );
            }else{
                row.push(<option value={i} key={i}>{i}</option> );
            }
        }
        return row;
    }

    return (
        <>
            <Header/>
            <div className='blank'></div>
            <div className='account home-content'>
                <div className='container'>
                    <div className='breadcrumbs'>
                        <div><Link to="/dashboard">Dashboard / </Link> <strong>Account</strong></div>
                    </div>
                    <div className='title'>Account Details</div>
                    <ul className="nav nav-pills alyya-tabs">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="pill" href="#personal-info">Personal Information</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="pill" href="#org-info">Organization information</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="pill" href="#change-pwd">Change Password</a>
                        </li>
                    </ul>
                    <div className="tab-content alyya-tabs-content">
                        <div className="tab-pane container active" id="personal-info">
                            {isLoading ? <LoadingSpinner/> :
                                <div className='account-content'>
                                    <form className=' alyya-form'>
                                        <div className='row'>
                                            <div className='col-md-4 mb-5'>
                                                {/* {uploadImage ? <img className='profile-pic' src={URL.createObjectURL(uploadImage)} alt="upload"/> : (userData?.attributes?.image ? <img className='profile-pic' src={userData?.attributes?.image} alt="upload"/> : <img className='profile-pic' src="/images/login_banner.png" alt="upload"/>)} */}
                                                {uploadImage ? <img className='profile-pic' src={URL.createObjectURL(uploadImage)} alt="upload"/> : (userData?.attributes?.image ? <img className='profile-pic' src={userData?.attributes?.image} alt="upload"/> : <img className='profile-pic' src="/images/userr.png" alt="upload"/>)}
                                                <div className="button-group">
                                                { isAdded && userData?.attributes?.image && <button className="border-only" type="button" onClick={()=>{deletePhoto()}}>Delete</button>}
                                                    <div className="box asImage" onChange={uploadPhoto}>
                                                        <input type="file" id="uploadImage" name='uploadImage'
                                                               className="inputfile" accept="image/*"/>
                                                    </div>
                                                    {/* {!uploadImage && <label htmlFor="uploadImage"
                                                           className="filled-button text-center" style={{cursor: 'pointer'}}>Add</label>}
                                                    {uploadImage && <label htmlFor="uploadImage"
                                                           className="filled-button text-center" style={{cursor: 'pointer'}}>Replace</label>} */}
                                                    {isAdded ? <label htmlFor="uploadImage" className="filled-button text-center" style={{cursor: 'pointer'}}>Replace</label> : <label htmlFor="uploadImage" className="filled-button text-center" style={{cursor: 'pointer', width: '100%'}}>Add</label>}
                                                </div>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='account-form' style={{maxWidth: '430px'}}>
                                                    {resultMessage &&
                                                    <p className='formErrors mb-2'>{resultMessage}</p>}
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className='d-block style-label'>Full name </label><input
                                                        className='style-input' type="text" placeholder='Full Name'
                                                        name="username" value={inputValues.username}
                                                        onChange={(e) => handleChange(e)}/>
                                                    </div>
                                                    {validation.username &&
                                                    <p className='formErrors'>{validation.username}</p>}
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className='d-block style-label'>Phone
                                                            number </label><input disabled={true}
                                                                                  className='style-input disabled' type="text"
                                                                                  value={userData?.attributes?.phone_number}
                                                                                  placeholder='Mobile Number'/>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className='d-block style-label'>Email ID </label><input
                                                        disabled={true} className='style-input disabled' type="text"
                                                        value={userData?.attributes?.email} placeholder='Email'/>
                                                    </div>
                                                    <div className='d-flex justify-content-end align-items-center mt-4'>
                                                        {isLoading ? <div className='position-relative'>
                                                            <button className="submit-btn m-0" disabled={true}
                                                                    type="button">Update
                                                            </button>
                                                            <img className='position-absolute'
                                                                 style={{"top": "30%", "right": "30%"}}
                                                                 src='/images/default.gif' width={'16px'}
                                                                 height={'16px'}/></div> : <button onClick={() => {
                                                            updateProfile()
                                                        }} className="submit-btn m-0" type='button'>Update</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                        <div className="tab-pane container" id="org-info">
                            {isLoading ? <LoadingSpinner/> :
                                <div className='account-content'>
                                    <form className='alyya-form' testID="submit_Form" onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input'
                                                      testID="organization_name_field"
                                                       value={organization.organization_name} name="organization_name"
                                                       id="organization_name" placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                <label className="did-floating-label">Organization Name</label>
                                                {orgValidation.organization_name &&
                                                <p className='formErrors mb-2' testID="organization_name_field_err" >{orgValidation.organization_name}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input'
                                                       testID="contact_person_field"
                                                       value={organization.contact_person} name="contact_person"
                                                       id="contact_person" placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                <label className="did-floating-label">Contact person</label>
                                                {orgValidation.contact_person &&
                                                <p className='formErrors mb-2' testID="contact_person_field_err">{orgValidation.contact_person}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input'
                                                       testID="contact_number_field"
                                                       value={organization.contact_number} name="contact_number"
                                                       id="contact_number" placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                 <label className="did-floating-label">Contact Number</label>
                                                {orgValidation.contact_number &&
                                                <p className='formErrors mb-2' testID="contact_number_field_err">{orgValidation.contact_number}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input' value={organization.email}
                                                  data-testid="email_field"
                                                       name="email" id="email" placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                <label className="did-floating-label">Email</label>
                                                {orgValidation.email &&
                                                <p className='formErrors mb-2' data-testid="email_field_err">{orgValidation.email}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input'
                                                testID="number_of_employees_field"
                                                       value={organization.number_of_employees}
                                                       name="number_of_employees" id="number_of_employees"
                                                       placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                <label className="did-floating-label">Number of employees</label>
                                                {orgValidation.number_of_employees &&
                                                <p className='formErrors mb-2' testID="number_of_employees_field_err">{orgValidation.number_of_employees}</p>}
                                              {Object.keys(companyMessage).filter((items) => {
                                                                return (companyMessage[items] === 'Number of employees must be an integer')
                                                            }).map((item, index) => {
                                                                return companyMessage && <p className='formErrors p-2'>{companyMessage[item]}</p>
                                                            })}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input' id="vat" name="vat" data-testid="Vat_field"
                                                       value={organization.vat} onChange={(e) => changeValue(e)}
                                                       placeholder=' '/>
                                                 <label className="did-floating-label">VAT</label>
                                                {orgValidation.vat &&
                                                <p className='formErrors mb-2' data-testid="Vat_field_err">{orgValidation.vat}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">         
                                                <input type="text" className='style-input did-floating-input' id="registration" testID="registration_field"
                                                       name="registration" value={organization.registration}
                                                       onChange={(e) => changeValue(e)} placeholder=' '/>
                                                 <label className="did-floating-label">Registration</label>
                                                {orgValidation.registration &&
                                                <p className='formErrors mb-2' testID="registration_field_err">{orgValidation.registration}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <input type="text" className='style-input did-floating-input' value={organization.address}
                                                       name="address" id="address" placeholder=' '
                                                       onChange={(e) => changeValue(e)}/>
                                                 <label className="did-floating-label">Address</label>
                                                {orgValidation.address &&
                                                <p className='formErrors mb-2'>{orgValidation.address}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-6'>
                                            <div className="did-floating-label-content">
                                                <select name="inception" className='style-input did-floating-select-bg did-floating-input' id="inception" 
                                                testID="year_field"
                                                        value={organization.inception} onChange={(e) => changeValue(e)}>
                                                    <option value="">Year of Inception</option>
                                                    {YearsOfManufactures().sort((a,b)=>b.key-a.key)}
                                                </select>
                                                {organization?.inception && <label class="did-floating-label">Year of Inception</label>}
                                                {orgValidation.inception &&
                                                <p className='formErrors mb-2' testID="year_field_err">{orgValidation.inception}</p>}
                                            </div>
                                            </div>
                                            <div className='col-sm-12'>
                                                <label className='style-label'>Organization Documents Upload <span className='accepted-file' data-testid="valid-file-format">(pdf/docx/png/jpg/jpeg)</span></label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div className="box" onChange={uploadGOSI}>
                                                            <input type="file" id="GOSI" name='GOSI'
                                                                   className="inputfile"/>
                                                            <label htmlFor="GOSI"><img src="/images/upload.png"
                                                                                       alt="upload"/><span>{GOSI.name ? GOSI.name : 'GOSI certificate'}</span></label>
                                                            {(companyDetails?.attributes?.gosi_certificate) && (<a href={companyDetails?.attributes?.gosi_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                        </div>
                                                        {orgValidation.GOSI &&
                                                        <p className='formErrors mb-2'>{orgValidation.GOSI}</p>}
                                                        {Object.keys(companyMessage).filter((items) => {
                                                                return (companyMessage[items].split(' ')[0] === 'Gosi' )
                                                            }).map((item, index) => {
                                                                return companyMessage && <p className='formErrors p-2'>{companyMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className="box" onChange={uploadSaudization}>
                                                            <input type="file" id="saudization" name='saudization'
                                                                   className="inputfile"/>
                                                            <label htmlFor="saudization"><img src="/images/upload.png"
                                                                                              alt="upload"/><span>{saudization.name ? saudization.name : 'Saudization certificate'}</span></label>
                                                            {(companyDetails?.attributes?.saudization_certificate) && (<a href={companyDetails?.attributes?.saudization_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                        </div>
                                                        {orgValidation.saudization &&
                                                        <p className='formErrors mb-2'>{orgValidation.saudization}</p>}
                                                        {Object.keys(companyMessage).filter((items) => {
                                                                return (companyMessage[items].split(' ')[0] === 'Saudization' )
                                                            }).map((item, index) => {
                                                                return companyMessage && <p className='formErrors p-2'>{companyMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className="box" onChange={uploadZakat}>
                                                            <input type="file" id="zakat" name='zakat'
                                                                   className="inputfile"/>
                                                            <label htmlFor="zakat"><img src="/images/upload.png" alt="upload"/><span>{zakat.name ? zakat.name : 'Zakat certificate'}</span></label>

                                                            {(companyDetails?.attributes?.zakat_certificate) && (<a href={companyDetails?.attributes?.zakat_certificate} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                        </div>
                                                        {orgValidation.zakat &&
                                                        <p className='formErrors mb-2'>{orgValidation.zakat}</p>}
                                                        {Object.keys(companyMessage).filter((items) => {
                                                                return (companyMessage[items].split(' ')[0] === 'Zakat' )
                                                            }).map((item, index) => {
                                                                return companyMessage && <p className='formErrors p-2'>{companyMessage[item]}</p>
                                                            })}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className="box" onChange={uploadCompany}>
                                                            <input type="file" id="company" name='company'
                                                                   className="inputfile"/>
                                                            <label htmlFor="company"><img src="/images/upload.png"
                                                                                          alt="upload"/><span>{company.name ? company.name : 'Company registration'}</span></label>
                                                            {(companyDetails?.attributes?.company_registration) && (<a href={companyDetails?.attributes?.company_registration} target="_blank"><img src={'/images/file.png'} width={'32'} height={'32'} alt='upload' /></a>) }
                                                        </div>
                                                        {orgValidation.company &&
                                                        <p className='formErrors mb-2'>{orgValidation.company}</p>}
                                                        {Object.keys(companyMessage).filter((items) => {
                                                                return (companyMessage[items].split(' ')[0] === 'Company' )
                                                            }).map((item, index) => {
                                                                return companyMessage && <p className='formErrors p-2'>{companyMessage[item]}</p>
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 mt-3'>
                                            <div className="did-floating-label-content">
                                                <textarea name="description" value={organization.description}
                                                          onChange={(e) => changeValue(e)} className='style-input pt-2 did-floating-textarea'
                                                          rows="5" placeholder=' '></textarea>
                                                {orgValidation.description &&
                                                <p className='formErrors mb-2'>{orgValidation.description}</p>}
                                            <label className="did-floating-label">Add description</label>   
                                           
                                            </div>
                                            </div>
                                            {/*<div className='col-sm-12'>*/}
                                            {/*    <label className="checkbox-style my-3">*/}
                                            {/*        <span className="small">Disclaimer if any Lorem Ipsum</span>*/}
                                            {/*        <input type="checkbox" checked={checked}*/}
                                            {/*               onChange={e => setChecked(e.target.checked)}/>*/}
                                            {/*        <span className="checkmark"></span>*/}
                                            {/*    </label>*/}
                                            {/*    {orgValidation.checkedError &&*/}
                                            {/*    <p className='formErrors mb-2'>{orgValidation.checkedError}</p>}*/}
                                            {/*</div>*/}
                                            <div className='btn-groups'>
                                                {/*{isLoading ? <div className='position-relative'><button className="link-buttons" disabled={true} type="button">Save</button> <img className='position-absolute' style={{"top" : "20%", "right" : "11%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :*/}
                                                {/*    <button type="button" className='link-buttons' onClick={() => {updateCompanyDetails('in_draft')}}>Save</button>*/}
                                                {/*}*/}
                                                {isLoading ? <div className='position-relative'><button className="submit-buttons" disabled={true} type="button">Submit for Review</button> <img className='position-absolute' style={{"top" : "30%", "right" : "4%"}} src='/images/default.gif' width={'16px'} height={'16px'}/></div> :
                                                    <button type="submit" className='submit-buttons w-auto mx-auto mt-3 'data-testid="sbumitButton" >Update & Submit for Review</button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                        <div className="tab-pane container" id="change-pwd">
                            {isLoading ? <LoadingSpinner/> :
                                <div className='account-content'>
                                    <form className='account-form' style={{maxWidth: '500px'}}>
                                        {changePasswordMessage &&
                                        <p className='formErrors mb-2'>{changePasswordMessage}</p>}
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className='d-block style-label'>Current Password </label>
                                            <div className='position-relative'>
                                                <input className='style-input'
                                                       type={passwordShown ? "text" : "password"} name='currentPassword'
                                                       value={inputValues.currentPassword} placeholder='Password'
                                                       onChange={(e) => handleChange(e)}/>
                                                <img src={passwordShown ? "/images/eyeshow.png" : "/images/eyehide.png"}
                                                     className="password-hide" alt="password" onClick={togglePassword}/>
                                            </div>

                                        </div>
                                        {pwdValidation.currentPassword &&
                                        <p className='formErrors'>{pwdValidation.currentPassword}</p>}
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className='d-block style-label'>New Password </label>
                                            <div className='position-relative'>
                                                <input className='style-input'
                                                       type={newPasswordShown ? "text" : "password"} name="newPassword"
                                                       value={inputValues.newPassword} placeholder='New Password'
                                                       onChange={(e) => handleChange(e)}/>
                                                <img
                                                    src={newPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"}
                                                    className="password-hide" alt="password"
                                                    onClick={toggleNewPassword}/>
                                            </div>
                                        </div>
                                        {pwdValidation.newPassword &&
                                        <p className='formErrors'>{pwdValidation.newPassword}</p>}
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className='d-block style-label'>Re enter New Password </label>
                                            <div className='position-relative'>
                                                <input className='style-input' name="confirmPassword"
                                                       type={confirmPasswordShown ? "text" : "password"}
                                                       value={inputValues.confirmPassword}
                                                       placeholder='Re enter New Password'
                                                       onChange={(e) => handleChange(e)}/>
                                                <img
                                                    src={confirmPasswordShown ? "/images/eyeshow.png" : "/images/eyehide.png"}
                                                    className="password-hide" alt="password"
                                                    onClick={toggleConfirmPassword}/>
                                            </div>

                                        </div>
                                        {pwdValidation.confirmPassword &&
                                        <p className='formErrors'>{pwdValidation.confirmPassword}</p>}
                                        <div className='d-flex justify-content-end align-items-center mt-4'>
                                            {isLoading ? <div className='position-relative'>
                                                <button className="submit-btn m-0" disabled={true} type="button">Change
                                                    password
                                                </button>
                                                <img className='position-absolute'
                                                     style={{"top": "30%", "right": "16%"}} src='/images/default.gif'
                                                     width={'16px'} height={'16px'}/></div> : <button onClick={() => {
                                                changePassword()
                                            }} className="submit-btn m-0" type="button">Change password</button>}
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AdminAccount;
