import React, { useRef, useState, useEffect, useContext} from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import {Link, useParams, useNavigate,useLocation} from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../shared/Loader';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { CountContext } from './CountContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = makeStyles({
    myAlert: {
      backgroundColor: "#ff8d27",
      boxShadow: 'none',
      fontFamily: 'Montserrat',
    }
  });

  const customSnackbarStyles = {
    marginTop: '7rem',
  };

function ViewEquipmentDetail() {
    const classes = useStyles();
    const props = useParams();
    const location = useLocation()
    const [copied, setCopied] = useState(false);
    const [copiedd, setCopiedd] = useState(false);
    const [isRemoved, setIsRemoved] = useState(false);
    const [isVendorLoading, setIsVendorLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [cartItems, setCartItems] = React.useState({});
    const [open, setOpen] = React.useState(false);                            
    const [snackBar, setSnackBar] = React.useState('');
    const [isFavourite, setIsFavourite] = useState(false);
    const [isAdded, setIsAdded] = useState(false); 
    const [isDisabled, setIsDisabled] = useState();
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const { favCount, favouriteCount, cartsTotal, cartCount } = useContext(CountContext);
    useEffect(() => {
       favouriteCount()
    }, [favCount])
    useEffect(() => {
        cartCount()
     }, [cartsTotal])
    const copy = () => {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
    }
    setTimeout(() => {
        setCopied(false);
    }, 3000);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    
    setTimeout(() => {
        setCopiedd(false);
    }, 3000);
    
    setTimeout(() => {
        setIsRemoved(false);
    }, 3000);

    const addQuantity = (e, attributeName) => {
        const {name, value} = e.target;  
        let data = [cartItems];
        data[0].attributes[attributeName] = (parseInt(data[0].attributes[attributeName]) +1);
        setCartItems(data[0]);
        setOpen(true);
        setSnackBar("Quantity Increased");
        if(isAddedCart){
        updatedCartQuantity(data[0]);
        }
        setInputValue(prevInputValue => ({ ...prevInputValue, quantity: (prevInputValue.quantity + 1) }));
        setQuantities((prev) => prev+1)

    }
    const removeQuantity = (e, attributeName) => {
        const {name, value} = e.target;
        let data = [cartItems];
        if(parseInt(data[0].attributes[attributeName]) > 1){
            data[0].attributes[attributeName] = (parseInt(data[0].attributes[attributeName]) - 1);
            setCartItems(data[0]);
            setOpen(true);
            setSnackBar("Quantity Decreased");
            if(isAddedCart){
            updatedCartQuantity(data[0]);
            }
            setInputValue(prevInputValue => ({ ...prevInputValue, quantity: (prevInputValue.quantity -1) }));
            setQuantities((prev) => prev-1);
        }
    }
    const addingQuantity = () => {
        setOpen(true);
        setSnackBar("Quantity Increased");
        setInputValue(prevInputValue => ({ ...prevInputValue, quantity: (prevInputValue.quantity + 1) }));
      }
      const removingQuantity = () => {
        if (inputValues.quantity !== 1) {
          setOpen(true);
          setSnackBar("Quantity Decreased");
        setInputValue(prevInputValue => ({ ...prevInputValue, quantity: Math.max(prevInputValue.quantity - 1, 1) }));
        }
      }
    const updatedCartQuantity = (cartItemObj) => {
        const {quantity, start_date, end_date, order_id, id, zone} = cartItemObj.attributes
        const updateCartData = {
            quantity: quantity,
            start_date: start_date,
            end_date: end_date,
            order_id: order_id,
            zone: zone
        }
        axios.put(`${appConstant.API_URL}/bx_block_order_management/cart_items/${id}`, updateCartData, params)
            .then(({data}) => {
                // setIsLoading(false);
                if (data.status === 200) {
                    // setResultMessage('Successfully sent.');  
                    cartList(data.data);
                    if(isAddedCart){
                        cartCount();
                    }
                    
                }else {
                    setResultMessage(data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                // props.changeLogin(true);
            });
    }
    const updateCart = (cartItemObj) => {
        // setIsAddedCart(false)
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_capacities`, params)
                    .then(res => {
                        if (res.data.status === 200) {
                            const capy = res.data.capacities.filter(each => each.value === cartItemObj.attributes.capacity).map(item => item);
                            const capyItem = capy.filter(each => each.sub_category_id===cartItemObj.attributes.equipment.sub_category_id).map(item => item.id);
                            setInputValue({
                                capacity:  capyItem[0],
                                quantity: cartItemObj.attributes.quantity,
                                startDate: Moment(cartItemObj.attributes.start_date).format("YYYY-MM-DD"),
                                endDate: Moment(cartItemObj.attributes.end_date).format("YYYY-MM-DD"),
                               // location: cartItemObj.attributes.equipment.base_location_id,
                                zone: cartItemObj.attributes.zone
                            })
                        }else {
                        }
                    })
                    .catch(error => {
                    });
        
    }
    const [comp, setComp] = useState('');
    const [url, setUrl] = useState('');
    const [youtubeId, setYoutubeId] = useState('');
    const [capacityName, setCapacityName] = useState('');
    const getYouTubeId = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        const res =  (match&&match[7].length==11)? match[7] : false;
        setYoutubeId(res);
    }
    const changeImage = (value) =>{
        setComp(value);
        setUrl("");
    }
    const showVideo = (value) => {
        setComp("");
        setUrl(value);
    }
    function handleChange(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
        if(name == 'capacity') {
            const index = e.nativeEvent.target.selectedIndex;
            const equipmentId = e.nativeEvent.target[index].getAttribute('data-equipmentid');
            const CapacityText = e.nativeEvent.target[index].text;
            setCapacityName(CapacityText);
            equipmentThroughCapacity(equipmentId);
        }
    }
    const addLocation = (e) => {
        const {name, value} = e.target;
        setZones(value);
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
        .then(res => {
            if (res.data.status === 200) {
                const loc = ((res.data.locations.filter(item => item.id === Number(value)).map(key => key))[0]).name
                let data = [cartItems];
                data[0].attributes.zone = loc;
                setCartItems(data[0]);
                axios.get(`${appConstant.API_URL}/bx_block_order_management/check_equipment_in_cart?order_id=${data[0].attributes.order_id}&equipment_id=${data[0].attributes.equipment_id}&capacity=${data[0].attributes.capacity}&quantity=${data[0].attributes.quantity}&zone=${data[0].attributes.zone}&start_date=${Moment(data[0].attributes.start_date).format('YYYY-MM-DD')}&end_date=${Moment(data[0].attributes.end_date).format('YYYY-MM-DD')}`, params)
                              .then(res => {
                                if (res.status === 200 && res.data.is_present === true) {
                                  setIsAddedCart(true)
                                  setOpen(true);
                                  setSnackBar("Item already present in cart")
                                }else {
                                  setIsAddedCart(false);
                                  updateCart(data[0]);
                            }
                    })
                    .catch(error => {
                        
                    });
            }else {
            }
        })
        .catch(error => {
        });
    }
    const handleCapacityChange = (e) => {
        const {name, value} = e.target;
        setCapacities(value);
        let data = [cartItems];
        setCartItems(data[0]);
        setIsAddedCart(false);
        if(name == 'capacity') {
            const index = e.nativeEvent.target.selectedIndex;
            const equipmentId = e.nativeEvent.target[index].getAttribute('data-equipmentid');
            const CapacityText = e.nativeEvent.target[index].text;
            setCapacityName(CapacityText);
            equipmentThroughCapacity(equipmentId);
        }
    }
    function handleStartDateChange(e) {
        const { name, value } = e.target;
        let data = [cartItems];
        const date = new Date(value);
        const momentDate = Moment(date).format("YYYY-MM-DDTHH:mm:ss.sssZ");
        data[0].attributes['start_date'] = momentDate;
        setCartItems(data[0]);
        axios.get(`${appConstant.API_URL}/bx_block_order_management/check_equipment_in_cart?order_id=${data[0].attributes.order_id}&equipment_id=${data[0].attributes.equipment_id}&capacity=${data[0].attributes.capacity}&quantity=${data[0].attributes.quantity}&zone=${data[0].attributes.zone}&start_date=${Moment(data[0].attributes.start_date).format('YYYY-MM-DD')}&end_date=${Moment(data[0].attributes.end_date).format('YYYY-MM-DD')}`, params)
                              .then(res => {
                                if (res.status === 200 && res.data.is_present === true) {
                                  setIsAddedCart(true)
                                  setOpen(true);
                                  setSnackBar("Item already present in cart")
                                }else {
                                  setIsAddedCart(false);
                                  updateCart(data[0]);
                            }
                    })
                    .catch(error => {
                        
                    });
        setStartDates(value)
    }
    function handleEndDateChange(e) {
        const { name, value } = e.target;
        let data = [cartItems];
        const date = new Date(value);
        const momentDate = Moment(date).format("YYYY-MM-DDTHH:mm:ss.sssZ");
        data[0].attributes['end_date'] = momentDate;
        setCartItems(data[0]);
        axios.get(`${appConstant.API_URL}/bx_block_order_management/check_equipment_in_cart?order_id=${data[0].attributes.order_id}&equipment_id=${data[0].attributes.equipment_id}&capacity=${data[0].attributes.capacity}&quantity=${data[0].attributes.quantity}&zone=${data[0].attributes.zone}&start_date=${Moment(data[0].attributes.start_date).format('YYYY-MM-DD')}&end_date=${Moment(data[0].attributes.end_date).format('YYYY-MM-DD')}`, params)
                              .then(res => {
                                if (res.status === 200 && res.data.is_present === true) {
                                  setIsAddedCart(true)
                                  setOpen(true);
                                  setSnackBar("Item already present in cart")
                                }else {
                                  setIsAddedCart(false)
                                  updateCart(data[0]);
                            }
                    })
                    .catch(error => {
                        
                    });
        setEndDates(value);
    }
    useEffect(() => {
        getLocations();
        equipmentList();
        reviewRating();
    }, []);
   
    useEffect(() => {
        equipmentList();
        getLocations();
        reviewRating();
    }, [localStorage.getItem('stars')]);
    const rating = {
        size: 23,
        count: 5,
        color: "#757575",
        activeColor: "#f8b84e",
        value: Number(localStorage.getItem('stars')),
        edit: false,
        a11y: true,
        isHalf: false,
        emptyIcon: <i className="fa fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: newValue => {
            console.log(`Example 2: new value is ${newValue}`);
        }
    };
    const modalRating ={
        size: 15,
        count: 5,
        color: "#757575",
        activeColor: "#f8b84e",
        value: localStorage.getItem('stars'),
        edit: false,
        a11y: true,
        isHalf: false,
        emptyIcon: <i className="fa fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
        onChange: rateValue => {
            console.log(`Example 2: rate value is ${rateValue}`);
        }
    }
    const [equipments, setEquipments] = React.useState({});
    const [categories, setCategories] = React.useState({});
    const [subCategories, setSubCategories] = React.useState();
    const [capacity, setCapacity] = React.useState({});
    const [capacities, setCapacities] = React.useState({});
    const [locations, setLocations] = React.useState([]);
    const [cartLocation,setCartLocation]=React.useState("")
    const [accounts, setAccounts] = React.useState({});
    const [vendors, setVendors] = React.useState({});
    const [amountTypeData, setAmountTypeData] = React.useState('week');
    const [reviews, setReviews] = React.useState([]);
    const [quantities, setQuantities] =  React.useState(1);
    const [zones, setZones] = React.useState();
    const [startDates, setStartDates] = React.useState();
    const [endDates, setEndDates] = React.useState();
    const [isAddedCart, setIsAddedCart] = React.useState(false);
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const equipmentList = () => {
        
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/${props.id}`, params)
            .then(res => {
                setIsLoading(false);

                if (res.data.status === 200) {
                    localStorage.setItem('stars', Math.round(res?.data?.data.attributes.rating));
                    localStorage.setItem('total_reviews', res?.data?.data.attributes.total_reviews);
                    setEquipments(res?.data?.data);
                    setIsFavourite(res?.data?.data?.attributes?.is_favourite)
                    cartList(res?.data?.data);
                    setComp(res?.data?.data?.attributes?.images[0].url);
                    setCategories(res?.data?.category);
                    setSubCategories(res.data.data.attributes.sub_category_id)
                    getCapacityList(res.data.data.attributes.sub_category_id)
                    setAccounts(res?.data?.accounts);
                    getYouTubeId(res?.data?.data?.attributes?.video_url);
                    const inputData  = {
                        capacity: (res?.data?.data?.attributes?.capacity_id) ? res?.data?.data?.attributes?.capacity_id : '',
                        quantity: (res?.data?.data?.attributes?.quantity) ? res?.data?.data?.attributes?.quantity : 1,
                        // startDate: Moment().format('YYYY-MM-DD'),
                        // endDate: Moment().add(3,'d').format('YYYY-MM-DD'),
                        startDate: '',
                        endDate: '',
                        location: (res?.data?.data?.attributes?.base_location_id) ? res?.data?.data?.attributes?.base_location_id : '',
                        zone: (res?.data?.data?.attributes?.base_location) ? res?.data?.data?.attributes?.base_location : ''
                    }
                    setCapacityName(res?.data?.data?.attributes?.capacity.value);
                    setInputValue(inputData);
                }else {
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }


function getCapacityList (subCategoryId){
    axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_capacities?sub_category_id=${subCategoryId}`, params)
    .then(res => {
        if (res.data.status === 200) {
             const modifiedCapacities = res.data.capacities.map(item=>({value:item.value,id:item.id}))
             setCapacity(modifiedCapacities)
        }else{
            return;
        }
    })
    .catch(error => {
    });
}

    function areObjectsEqual(obj1, obj2) {
        if (obj1 === obj2) {
          return true;
        }
      
        
        if (!obj1 || !obj2) {
          return false;
        }
      
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
          return false;
        }
      
        for (let prop in obj1) {
          if (!obj2.hasOwnProperty(prop)) {
            return false;
          }
          if (obj1[prop] !== obj2[prop]) {
            return false;
          }
        }
      
        return true;
      }

    const cartList =  (equipmentt) => {
     
        // setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_order_management/cart_items`, params)
            .then(res => {
                if (res.data.status === 200) { 
                let item=res.data.data.filter(each=> each.attributes.equipment_id === Number(equipmentt.id))
              
                      if(location?.state?.cartItemId){
                       
                         item = res.data.data.filter(each=> each.attributes.equipment_id === Number(equipmentt.id) && location?.state?.cartItemId===each.id)
                      }
          
                   if(item.length) {
                    const cart = item[(item.length - 1)]
                    axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
                   .then(res => {
                     if (res.data.status === 200) {
                      let ids=   res.data.locations.find(item=>item.name===cart.attributes.zone)['id']
                      setZones(ids)
                   
                }else {
                    return;
                }
            })
                    setCartItems(cart);
                    
                    
                    setStartDates(cart.attributes.start_date)
                    // setInputValue({startDate:Moment(cart.attributes.startDate),endDate:Moment(cart.attributes.endDate)})
                    setInputValue(prevInputValue => ({ ...prevInputValue, startDate:cart.attributes.start_date,endDate:cart.attributes.end_date }));
                    setEndDates(cart.attributes.end_date)
                    setQuantities(cart.attributes.quantity)
                    axios.get(`${appConstant.API_URL}/bx_block_order_management/check_equipment_in_cart?order_id=${cart.attributes.order_id}&equipment_id=${cart.attributes.equipment_id}&capacity=${cart.attributes.capacity}&quantity=${cart.attributes.quantity}&zone=${cart.attributes.zone}&start_date=${Moment(cart.attributes.start_date).format('YYYY-MM-DD')}&end_date=${Moment(cart.attributes.end_date).format('YYYY-MM-DD')}`, params)
                              .then(res => {
                                if (res.status === 200 && res.data.is_present === true) {
                                  setIsAddedCart(true)
                                }else {
                                  setIsAddedCart(false)
                            }
                    })
                    .catch(error => {
                        
                    });
                    axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_capacities`, params)
                    .then(res => {
                        if (res.data.status === 200) {
                            const capy = res.data.capacities.filter(each => each.value === cart.attributes.capacity).map(item => item);
                            const capyItem = capy.filter(each => each.sub_category_id===cart.attributes.equipment.sub_category_id).map(item => item.id);
                            setCapacities(capyItem[0])
                        }else {
                        }
                    })
                    .catch(error => {
                    });
                    setCapacities(cart.attributes.capacity)
                    const cartData = {
                        capacity:  cart.attributes.equipment.capacity_id,
                        quantity:  cart.attributes.quantity,
                        startDate: Moment(cart.attributes.start_date).format('YYYY-MM-DD'),
                        endDate:   Moment(cart.attributes.end_date).format('YYYY-MM-DD')
                    }

                    axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/${props.id}`, params)
                    .then(res => {
                        setIsLoading(false);
                        if (res.data.status === 200) {
                            const inputData  = {
                                capacity: (res?.data?.data?.attributes?.capacity_id) ? res?.data?.data?.attributes?.capacity_id : '',
                                quantity: (res?.data?.data?.attributes?.quantity) ? res?.data?.data?.attributes?.quantity : cart.attributes.quantity,
                                //startDate: Moment().format('YYYY-MM-DD'),
                                startDate: cart.attributes.start_date ? Moment(cart.attributes.start_date).format('YYYY-MM-DD') : Moment().format('YYYY-MM-DD'),
                              //  endDate: Moment().add(7,'d').format('YYYY-MM-DD'),
                                endDate: cart.attributes.end_date ? Moment(cart.attributes.end_date).format('YYYY-MM-DD') : Moment().format('YYYY-MM-DD'),
                            }
            
                        }else {
            
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                    });
                   }
                }else {
                    setCartItems([]);
                }
            })
            .catch(error => {
                
            });
    }
    useEffect(() => {
      
        cartList(equipments);
    
    }, []);
   

    const reviewRating = () => {
        setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_reviews/reviews/equipment_reviews?id=${props.id}`, params)
            .then(res => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setReviews(res.data);  
                }else {
                    setReviews([]);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }  
   
    const addToCart = (equipmentId) => {
       
        if (inputValues.startDate === '' && inputValues.endDate === '') {
            setStartDateError('Start date is required');
            setEndDateError('End date is required');
          } 
        else if(inputValues.startDate === '') {
            setStartDateError('Start date is required');
            setEndDateError('');
        }
        else if(inputValues.endDate === '') {
            setEndDateError('End date is required');
            setStartDateError('');
        }
        else {
            if(inputValues.startDate > inputValues.endDate) {
                setStartDateError('Start date should not be greater than end date');
                setEndDateError('');
            }
            else {
        setStartDateError('');
        setEndDateError('');
        const addToCartData = {
            equipment_id: equipmentId,
            capacity_id: inputValues.capacity,
            start_date: inputValues.startDate,
            end_date: inputValues.endDate,
            quantity: inputValues.quantity  ,
            // base_location_id: inputValues.location
            zone: inputValues.zone
        }
        if (!isAddingToCart) {
            setIsAddingToCart(true);
        axios.post(`${appConstant.API_URL}/bx_block_order_management/orders/add_to_cart`, addToCartData, params)
            .then(res => {
                if (res.data.status === 200) {
                    setOpen(true);
                    setSnackBar(res.data.message);
                    equipmentList();
                    cartCount();
                    window.location.reload();
                    setIsAddingToCart(false);
                   
                }else {
                   
                }
            })
            .catch(error => {
                
            });
        }   
        
    }
    }
    }
    const deleteCart = (equipmentId) => {
        const params = {
            headers: {
                "Content-Type": 'application/json',
                "Access-Control-Allow-Origin": '*',
                "token": localStorage.getItem('token')
            }
        };
        axios.get(`${appConstant.API_URL}/bx_block_order_management/cart_items`, params)
        .then(res => {
            if (res.data.status === 200) {
                const order = res.data.data.filter(each => each.attributes.equipment_id === Number(equipmentId))
                if (order.length) {
                    const cart = order[order.length - 1]; // Get the latest added item
                    const deleteCartData = {
                        id: cart.id,
                        order_id: cart.attributes.order_id
                    }
                    axios.post(`${appConstant.API_URL}/bx_block_order_management/cart_items/delete_cart`, deleteCartData, params)
                        .then(res => {
                            if (res?.status === 200) {
                                setOpen(true);
                                setSnackBar("Item removed from cart successfully")
                                equipmentList();
                                cartCount();
                                window.location.reload();
                            } else {
                            }
                        })
                        .catch(error => {

                        });
                }
            } else {

            }
        })
        .catch(error => {

        });
    }
    
    const getSubLocations = () => {
        axios.get(`${appConstant.API_URL}/bx_block_order_management/cart_items`, params)
        .then(res => {
            if (res.data.status === 200) {
                const len = (res.data.data.map(item => item.attributes.zone)).length;
                const locate = (res.data.data.map(item => item.attributes.zone))[len-1];
                if(locate) {
                    axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
                    .then(res => {
                        if (res.data.status === 200) {
                            const ids = (res.data.locations.filter(key => key.name===locate).map(item => item.id))[0];
                            setZones(ids)
                        }else {
                        }
                    })
                    .catch(error => {
                    });
                }
            }else {
            }
        })
        .catch(error => {
            
        });
       
    }
    const getLocations = () => {
       
        axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
            .then(res => {
                if (res.data.status === 200) {
                    
                    setLocations(res.data.locations);
                }else {
                }
            })
            .catch(error => {
            });
    }
    const [inputValues, setInputValue] = useState({
        capacity:  '',
        quantity: 1,
        startDate: '',
        endDate: '',
        location: '',
        zone: ''
    });
   
    const vendorList = (subCategory) => {
        const modal = document.getElementById('rentNow');
          const bsModal = new bootstrap.Modal(modal);
         bsModal.show();
        setIsVendorLoading(true);
        setStartDateError("")
        if(inputValues.startDate > inputValues.endDate) {
            bsModal.hide();
            setStartDateError('Start date should not be greater than end date');
            setEndDateError('');
        }
        
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/rent_now?sub_category_id=${subCategory}&capacity_id=${inputValues.capacity}&quantity=${inputValues.quantity}&start_date=${inputValues.startDate ? inputValues.startDate : Moment().format('DD-MM-YYYY')}&end_date=${inputValues.endDate ? inputValues.endDate : Moment().add(3, 'days').format('DD-MM-YYYY')}&base_location=${inputValues.location}`, params)
            .then(res => {
              
                setIsVendorLoading(false);
                if (res.data.status === 200) {
                    setVendors(res.data.data);
                    if (inputValues.startDate && inputValues.endDate){
                        const quoteStartDate = Moment(inputValues.startDate, 'DD/MM/YYYY');
                        const quoteEndtDate = Moment(inputValues.endDate, 'DD/MM/YYYY');
                        const diff = quoteEndtDate.diff(quoteStartDate, 'days');
                        if (parseInt(diff) < 7) {
                            setAmountTypeData('day');
                        } else if (parseInt(diff) < 30) {
                            setAmountTypeData('week');
                        } else {
                            setAmountTypeData('month');
                        }
                    }
                }else{
                    setVendors(res.data.data);
                }
                
            })
            .catch(error => {
                setIsVendorLoading(false);
                // props.changeLogin(true);
            });
    }
    const rentNow = (equipmentId, pricePerday, pricePerWeek, pricePerMonth, vendorId) => {
        const locationId= locations.find((e)=>e.name === inputValues.zone).id
        setIsVendorLoading(true);
        const rentNowParams = {
            equipment_id: equipmentId,
            capacity_id: inputValues.capacity,
            start_date: inputValues.startDate ? inputValues.startDate : Moment().format('DD-MM-YYYY'),
            end_date: inputValues.endDate ? inputValues.endDate : Moment().add(3, 'days').format('DD-MM-YYYY'),
            quantity: inputValues.quantity,
            base_location_id:locationId?locationId: inputValues.location,
            price_per_day: pricePerday,
            price_per_week: pricePerWeek,
            price_per_month: pricePerMonth,
            vendor_id: vendorId
        }
        axios.post(`${appConstant.API_URL}/bx_block_order_management/orders/quote_rent_now`, rentNowParams, params)
            .then(res => {
                setIsVendorLoading(false);
                if (res.data.status === 200) {
                    vendorList(equipments?.attributes?.sub_category_id);
                }else {
                    // setResultMessage(res.data.message);
                }
            })
            .catch(error => {
                setIsVendorLoading(false);
                // props.changeLogin(true);
            });
    }
    const equipmentThroughCapacity = (value) => {
        
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/equipment_of_capacity?capacity_id=${value}&sub_category_id=${equipments?.attributes?.sub_category_id}`, params)
            .then(res => {
                window.location.href = '/home/hire-equipment/view-equipment-detail/' + res.data.data.id;
            })
            .catch(error => {
            });
    }
    const addToFavourites = () => {
        if (!isFavourite) {
          // Logic for adding to favorites
          axios
            .post(`${appConstant.API_URL}/bx_block_favourites/favourites/add_to_favourite?equipment_id=${equipments.id}`, {}, params)
            .then(res => {
              if (res.status === 200) {
                setIsFavourite(true);
                setCopiedd(true);
                favouriteCount();
              }
            })
            .catch(error => {
              // Handle error
            });
        } else {
          // Logic for removing from favorites
          axios
            .put(`${appConstant.API_URL}/bx_block_favourites/favourites/remove_to_favourite?equipment_id=${equipments.id}`, {}, params)
            .then(res => {
              if (res.status === 200) {
                setIsFavourite(false);
                setIsRemoved(true);
                favouriteCount();
              }
            })
            .catch(error => {
              // Handle error
            });
        }
      };
    const favouriteList = () => {
        // setIsLoading(true);
        axios.get(`${appConstant.API_URL}/bx_block_catalogue/equipments/${props.id}`, params)
            .then(res => {
                if (res.data.status === 200) {
                    const item = res.data.data.id;
                      axios.get(`${appConstant.API_URL}/bx_block_favourites/favourites`, params)
                        .then(res => {
                            if (res.status === 200) {     
                            const matchedItem = res.data.data.filter(each => each.attributes.equipment_id === Number(item));
                            const matchedId = matchedItem[0].attributes.equipment_id;
                            if(Number(matchedId)===Number(item)) {
                                setIsAdded(true)
                            }
                            }else {
                    
                            }
                        })
                    .catch(error => {
                        
                    });
                    
                }else {
                }
            })
            .catch(error => {
            });
            }
        
        useEffect(() => {
            favouriteList();
            favouriteCount();
            getSubLocations();
        }, []);
       
    return (
        <>
      
            <HireHeader id="1"/>
            <Snackbar open={open} autoHideDuration={1000} anchorOrigin={{ vertical: "top", horizontal: "right"}} style={customSnackbarStyles} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className={classes.myAlert}>
                {snackBar}
                </Alert>
            </Snackbar>
            <div className='filter-blank'></div>
            <div className='view-equipment-detail home-content' key=''>
                {isLoading ? <LoadingSpinner/> :
                    <div className='container'>
                        <div className='breadcrumbs'>
                            <div><Link to="/home/hire-equipment">Home / Hire equipment / </Link> <strong
                                style={{textTransform: 'capitalize'}}>{equipments.attributes?.product_name}</strong>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-7 col-md-6 mb-4'>
                                <div className='product-gallery'>
                                    <div className='thumbnails'>
                                        {equipments?.attributes?.images?.length > 0 && equipments?.attributes?.images?.map((result, i) =>
                                            <>
                                                <img key={i} src={(result?.url) ? result?.url : '/images/default.png'}
                                                     alt="thumbnails" className='thumb active'
                                                     onClick={() => changeImage(result?.url)}/>
                                            </>
                                        )}
                                        {equipments?.attributes?.video_url ?
                                            <img key={equipments?.attributes?.video_url}
                                                 src={"https://img.youtube.com/vi/" + youtubeId + "/default.jpg"}
                                                 alt="thumb" className='thumb' onClick={() => showVideo(youtubeId)}/>
                                            : ""}

                                    </div>
                                    <div className='full-image'>
                                        {comp ?
                                            <div className='image-group'>
                                                <img src={comp ? comp : '/images/default.png'} alt="full"
                                                     className='product-full'/>
                                                    <button className='wishlist active' onClick={addToFavourites}>
                                                    {isFavourite ? (
                                                            <FavoriteIcon style={{ color: '#ff8d27' }} />
                                                            ) : (
                                                            <FavoriteBorderIcon style={{ color: 'grey' }} />
                                                            )}
                                                    </button>
                                                {!copiedd ? "" : <span className='tooltiptextt'>"Added!"</span>}
                                                {!isRemoved ? "" : <span className='tooltiptextt'>"Removed!"</span>}
                                                <button className='shared' onClick={copy}><img src="/images/share.png"
                                                                                               alt="share"
                                                                                               className='icons'/>
                                                </button>
                                                {!copied ? "" : <span className='tooltiptext'>"Copied!"</span>}
                                            </div>
                                            : ""
                                        }
                                        {url ?
                                            <iframe className='w-100' name="youtube embed"
                                                    allow="autoplay; encrypted-media" allowFullScreen height="250px"
                                                    src={"https://www.youtube.com/embed/" + url}></iframe>
                                            : ""
                                        }                                     
                                        {(equipments?.attributes?.status === 'available') &&
                                            <div className='button-group'>
                                                {(isAddedCart === false) &&
                                                    <button className='border-only' onClick={() => {
                                                        addToCart(equipments.id)
                                                    }} disabled={isAddingToCart}>Add To Cart</button>}
                                                {(isAddedCart === true) &&
                                                    <button className='border-only' onClick={() => deleteCart(equipments.id)}>Remove</button>}
                                                <button className='filled-button' type="button" data-bs-toggle="modal" data-testid="rent-now"
                                                        data-bs-target="#rentNow" onClick={() => {
                                                    vendorList(equipments?.attributes?.sub_category_id)
                                                }}>Rent Now
                                                </button>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-6'>
                                <h6 className='description mb-3'>Product ID &nbsp;&nbsp;
                                    <strong>{equipments?.id}</strong></h6>
                                <h4 className='product-name'
                                    style={{textTransform: 'capitalize'}}>{equipments?.attributes?.product_name}</h4>
                                <h6 className='description mb-3'>Year Of Manufacture &nbsp;&nbsp;
                                    <strong>{equipments?.attributes?.year_of_manufature}</strong></h6>

                                <ReactStars {...rating} />
                                <h6 className='description my-3'>Status &nbsp;&nbsp;<strong
                                    style={{textTransform: 'capitalize'}}>{equipments?.attributes?.status}</strong></h6>
                                <form className='filters mb-4'>
                                    <div className='row p-0'>
                                        <div className='col-sm-6'>
                                            <label htmlFor="capacity" className="label d-block mb-2">Capacity</label>
                                            {/* <select className="inputs d-block w-100 mb-3" id="capacity" name="capacity"
                                                    onChange={(e) => handleChange(e)} value={inputValues.capacity}>
                                                {capacity?.length > 0 && capacity?.map((result, i) =>
                                                    <>
                                                        <option key={result?.id}
                                                                value={result?.id} data-equipmentid={result.equipment_id}>{result?.value}</option>
                                                    </>
                                                )}
                                            </select> */}
                                            { (Object.keys(cartItems).map((key) => cartItems[key])).length > 0 ?  
                                                 <select className="inputs d-block w-100 mb-3" id="capacity" name="capacity"
                                                        onChange={(e) => handleCapacityChange(e)} value={capacities}>
                                                        {capacity?.length > 0 && capacity?.map((result, i) =>
                                                            <>
                                                                <option key={result?.id}
                                                                        value={result?.id} data-equipmentid={result.id}>{result?.value}</option>
                                                            </>
                                                        )}
                                                  </select>
                                                   :
                                                   <select className="inputs d-block w-100 mb-3" id="capacity" name="capacity"
                                                           
                                                        onChange={(e) => handleChange(e)} value={inputValues.capacity}>
                                                        {capacity?.length > 0 && capacity?.map((result, i) =>
                                                            <>
                                                                <option key={result?.id}
                                                                        value={result?.id} data-equipmentid={result.id}>{result?.value}</option>
                                                            </>
                                                        )}
                                                  </select>
                                               }
                                        </div>
                                        <div className='col-sm-6'>
                                            <label htmlFor="quantity" className="label d-block mb-2">Quantity</label> 
                                                {(Object.keys(cartItems).map((key) => cartItems[key])).length > 0 ?  
                                                   <div className='quantity-changed'>
                                                        <button className='input-number-change' type='button'
                                                                onClick={(e) => removeQuantity(e, 'quantity')}>-
                                                        </button>
                                                        <input className="input-number" id="quantity" name="quantity"
                                                               type="text"
                                                               value={quantities} readOnly/>
                                                        <button className='input-number-change' type='button'
                                                                onClick={(e) => addQuantity(e, 'quantity')}>+
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className='quantity-changed'>
                                                        <button className='input-number-change' type='button'
                                                                onClick={() => removingQuantity() }>-
                                                        </button>
                                                        <input className="input-number" id="quantity" name="quantity"
                                                               type="text"
                                                               value={inputValues.quantity} readOnly/>
                                                        <button className='input-number-change' type='button'
                                                                onClick={() => addingQuantity() }>+
                                                        </button>
                                                    </div>
                                                }
                                        </div>
                                        <div className='col-sm-12'>
                                            <label htmlFor="quantity" className="label d-block mb-2">Dates</label>
                                        </div>
                                        <div className='col-sm-6'>
                                        {(Object.keys(cartItems).map((key) => cartItems[key])).length > 0 ?  

                                                    <input type="date" className="inputs d-block date w-100 mb-2" name="startDate"
                                                    id="startDate" placeholder="From" value={Moment(startDates).format('YYYY-MM-DD')}
                                                    onChange={(e) => {handleStartDateChange(e)}}
                                                    min={Moment().format('YYYY-MM-DD')} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                                                   :
                                                   <input type="date" className="inputs d-block date w-100 mb-2" name="startDate"
                                                   id="startDate" placeholder="From" value={inputValues.startDate}
                                                   onChange={(e) => {handleChange(e);}}
                                                   min={Moment().format('YYYY-MM-DD')} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                                               }
                                            {startDateError==="Start date is required" && <span className="error-message pb-2">{startDateError}</span>}
                                        </div>
                                        
                                        <div className='col-sm-6'>
                                        {(Object.keys(cartItems).map((key) => cartItems[key])).length > 0 ?  
                                                    <input type="date" className="inputs d-block date w-100 mb-1" 
                                                    name="endDate"
                                                    id="endDate" placeholder="To" value={Moment(endDates).format('YYYY-MM-DD')}
                                                    onChange={(e) => handleEndDateChange(e)}
                                                    min={Moment(startDates).format('YYYY-MM-DD')} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                                                   :
                                                   <input type="date" className="inputs d-block date w-100 mb-1" 
                                                   name="endDate"
                                                   id="endDate" placeholder="To" value={inputValues.endDate}
                                                   onChange={(e) => handleChange(e)}
                                                   min={inputValues.startDate} onFocus={(e)=>e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                                               }
                                            {endDateError && <span className="error-message pb-2">{endDateError}</span>}
                                        </div>
                                        <div className="col-12">
                                            <div className="max-width-column">
                                            {startDateError==="Start date should not be greater than end date" && <span className="error-message pb-2">{startDateError}</span>}
                                            </div>
                                        </div>
                                        
                                        <div className='col-sm-12'>
                                           
                                            <label htmlFor="location" className="label d-block mb-2 pt-2">Zone/
                                                Location</label>
                                                { (Object.keys(cartItems).map((key) => cartItems[key])).length > 0 ?  
                                                 <select className="inputs d-block w-100 mb-3" id="location" name="zone"
                                                 onChange={(e) => addLocation(e)} value={zones}>
                                                    {locations?.length > 0 && locations?.map((result, i) =>
                                                        <>
                                                            <option key={result?.id}
                                                                    value={result?.id}>{result?.name}</option>
                                                        </>
                                                    )}
                                                    </select>
                                                   :
                                                   <select className="inputs d-block w-100 mb-3" id="location" name="zone"
                                                   onChange={(e) => handleChange(e)} value={inputValues.zone}  data-testid="zone-location">
                                                    {locations?.length > 0 && locations?.map((result, i) =>
                                                        <>
                                                            <option key={result?.id}  data-testid="zone-location-value"
                                                                    value={result?.name}>{result?.name}</option>
                                                        </>
                                                    )}
                                                   </select>
                                               }
                                                {/* {locations?.length > 0 && locations?.map((result, i) =>
                                                    <>
                                                        <option key={result?.id}
                                                                value={result?.id}>{result?.name}</option>
                                                    </>
                                                )} */}
    
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <hr className='mb-5'/>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <h5 className='title'>PRODUCT DESCRIPTION</h5>
                                <p className='description mb-4'>{equipments?.attributes?.description}</p>
                                <hr className='mb-4'/>
                                <h5 className='title'>SPECIFICATIONS</h5>
                                <table className='table table-borderless'>
                                    <tbody>
                                    <tr>
                                        <td><span className='description'>Equipment ID</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.equipment_number}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Maker</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.maker}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Model</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.model}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Year Of Manufacture</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.year_of_manufature}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>No. of Working Hours Completed</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.no_of_working_hours_completed}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>RC Available</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.rc_available ? 'Yes' : 'No'}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Insurance Available</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.insurance_available ? 'Yes' : 'No'}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Base location</span></td>
                                        <td><span
                                            className='description'><strong>{equipments?.attributes?.base_location}</strong></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className='description'>Company</span></td>
                                        <td><span
                                          testID="desc-orgName"
                                            className='description'><strong>{equipments?.attributes?.organisation_name}</strong></span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {reviews?.data?.length > 0 && <>
                            <hr className='mb-5'/>
                            <h5 className='title'>Reviews & Ratings</h5>
                            {reviews?.data?.length > 0 && reviews?.data?.map((result, i) =>
                                <>
                                    <div className='row'>
                                <div className='col-md-12'>
                                    <div className='d-flex align-items-start'>
                                        <img src={result?.attributes?.image ? result?.attributes?.image : '/images/profile.png'} alt="banner" className='rating-image'/>
                                        <div>
                                            <h6 className="customer-name">{result?.attributes?.customer_name}</h6>
                                            <p className='customer-desc'>{result?.attributes?.client_review}</p>
                                            <div className='d-flex mb-2'>
                                                <ReactStars size={15} value={result?.attributes?.equipment_rating} count={5} color={'#757575'} a11y={false} activeColor={'#f8b84e'} half={true} edit={false}
                                                emptyIcon={<i className="fa fa-star" />} halfIcon={<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />}/>
                                                <div className='product-rate ml-5'>{result?.attributes?.equipment_name}</div>
                                            </div>
                                            <div className='d-flex mb-2'>
                                                <ReactStars size={15} value={result?.attributes?.seller_rating} count={5} color={'#757575'} a11y={false} activeColor={'#f8b84e'} half={true} edit={false}
                                                            emptyIcon={<i className="fa fa-star" />} halfIcon={<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />}/>
                                                <div className='product-rate ml-5'>{result?.attributes?.company_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    <hr className='mb-5'/>
                                </>
                            )}
                        </>}
                    </div>
                }
                {/* modal */}
                <div className="modal back-shadow" id="rentNow" >
                    <div className="modal-dialog modal-dialog-centered modal-width">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" data-testid="modal-title">Rent Now</h4>
                                <button type="button" className="close-button" data-bs-dismiss="modal" >&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="table-responsive alyya-table">
                                    <table className="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th className='min-300'>Equipment & Capacity</th>
                                            <th className='min-210'>Category/Capacity</th>
                                            <th className='min-210'>Duration</th>
                                            <th>Quantity</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className='min-300'>
                                                <div className='d-flex align-items-center'>   
                                                    {equipments?.attributes?.images?.length > 0 && <img src={equipments?.attributes.images[0].url} className="" alt="equipment" />}
                                                    {equipments?.attributes?.images?.length === 0 && <img src="/images/login_banner.png" className="" alt="equipment" />}

                                                    <div>
                                                        <strong className='d-block' style={{textTransform: 'capitalize'}}>{equipments?.attributes?.product_name}</strong>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='min-210'>
    
                                                <span className='d-block mt-2'>{equipments?.attributes?.sub_category_name}</span>
                                                <span className='d-block mt-2'>{capacityName} Capacity</span>
                                            </td>
                                            <td className='text-start  min-300' width={'250px'}>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                                    <div className='col-6 px-0'><strong>{inputValues.startDate !== '' ? Moment( inputValues.startDate).format('DD-MM-YYYY') : Moment().format('DD-MM-YYYY')}</strong></div>
                                                    <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                                    <div className='col-6 px-0'><strong>{inputValues.endDate !== '' ? Moment(inputValues.endDate).format('DD-MM-YYYY') : Moment().add(3, 'days').format('DD-MM-YYYY')}</strong></div>
                                                </div>
                                            </td>
                                            <td>
                                                <strong>{Object.keys(cartItems).length>0 ? cartItems?.attributes?.quantity :inputValues?.quantity}</strong>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive alyya-table alyya-table-borderless">
                                    {isVendorLoading ? <LoadingSpinner/> :
                                        <table className="table ">
                                            <tbody>
                                            {vendors?.length > 0 && vendors?.map((result, i) =>
                                                <>
                                                    <tr>
                                                        <td className='min-300' width={'400px'}>
                                                            <div className='d-flex align-items-center'>
                                                                <img src={result?.attributes?.vendor?.vendor_image ? result?.attributes?.vendor?.vendor_image :  '/images/userr.png' } className=""
                                                                     alt={result?.attributes?.vendor?.full_name}/>
                                                                <div>
                                                                    <strong
                                                                        className='d-block mb-2'>{result?.attributes?.vendor?.full_name}</strong>
                                                                    <div
                                                                        className='d-flex justify-content-between align-items-center'>
                                                                        {/* <ReactStars {...modalRating} /> */}Rent Now
                                                                        <ReactStars value={result?.attributes?.vendor?.vendor_rating ? Math.round(result?.attributes?.vendor?.vendor_rating) : 0 } edit={false} size={15} count={5} color= {'#757575'} activeColor={"#f8b84e"} a11y={true} isHalf={false} emptyIcon= {<i className="fa fa-star"></i>}  halfIcon= {<i className="fa fa-star-half-alt" />} filledIcon= {<i className="fa fa-star" />} />
                                                                        <span
                                                                            className='rating ml-3 p-1'>{result?.attributes?.vendor?.vendor_review_count ? result?.attributes?.vendor?.vendor_review_count : 0} reviews</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='min-300' width={'400px'}>
                                                            <div className='row'>
                                                                <div className='col-8'>Mobilisation provided?</div>
                                                                <div className='col-4'>
                                                                    <strong>{result?.attributes?.mobilisation}</strong>
                                                                </div>
                                                                <div className='col-8 mt-2'>Zone/ Location</div>
                                                                <div className='col-4 mt-2'>
                                                                    <strong>{result?.attributes?.base_location}</strong>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='min-210' style={{ whiteSpace: 'nowrap' }}>
                                                            {amountTypeData === 'day' &&
                                                                <span className='d-block'><strong><div className='d-inline-block'>SAR</div> {result?.attributes?.price_per_day ? Number(result?.attributes?.price_per_day).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}/day</strong></span>}
                                                            {amountTypeData === 'week' &&
                                                                <span className='d-block'><strong><div className='d-inline-block'>SAR</div> {result?.attributes?.price_per_week ? Number(result?.attributes?.price_per_week).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}/week</strong></span>}
                                                            {amountTypeData === 'month' &&
                                                                <span className='d-block'><strong><div className='d-inline-block'>SAR</div> {result?.attributes?.price_per_month ? Number(result?.attributes?.price_per_month).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2, }).replace(/^(\D+)/, '') : 0}/month</strong></span>}
                                                        </td>
                                                        <td>
                                                            {(result.attributes.rent_now_visible === true) &&
                                                                <button className='table-success-button' type="button"
                                                                        onClick={() => {
                                                                            rentNow(result?.id, result?.attributes?.price_per_day, result?.attributes?.price_per_week, result?.attributes?.price_per_month, result?.attributes?.vendor?.id)
                                                                        }}>Rent Now</button>}
                                                            {(result.attributes.rent_now_visible === false) &&
                                                                <button className='table-success-button' type="button">
                                                                    Request Sent</button>}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                            {vendors?.length === 0 && <tr>
                                                <td colSpan='4'>Not Found.</td>
                                            </tr>}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <HireFooter/> */}
        </>
    )
};

export default ViewEquipmentDetail;