import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';
import Moment from 'moment';
import ScrollToTop from '../shared/ScrollToTop';
import { CountContext } from './CountContext';
function HireHeader(props) {
  const nav = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [cartTotal, setCartTotal] = useState(0);
  let [notifications, setNotifications] = useState([]);
  let [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const { favCount, favouriteCount, cartsTotal, cartCount } = useContext(CountContext);
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      nav('/');
    }
    isActivated();
    favouriteCount();
    cartCount();
    notificationCount();
  }, []);

  const homePage = () => {
    if (window.location.pathname === '/home/hire-equipment') {
      window.location.href = '/home/hire-equipment';
    } else {
      nav('/home/hire-equipment');
    }
  }
  const [inputValues, setInputValue] = useState({
    s: (searchParams?.get('s')) ? searchParams?.get('s') : ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const isActivated = () => {
    axios.get(`${appConstant.API_URL}/account_block/user_activate`, params)
      .then(res => {
        if (res?.status === 200) {
          if (res?.data?.activate) {

          } else {
            localStorage.clear('token');
            localStorage.clear('full_name');
            localStorage.clear('user_type');
            nav('/');
          }
        } else {
        }
      })
      .catch(error => {
      });
  }

  const notificationCount = () => {
    axios.get(`${appConstant.API_URL}/bx_block_notifications/notification/total_count`, params)
      .then(res => {
        if (res?.data?.status === 200) {
          localStorage.setItem('notificationCount', res?.data?.total_count);
        } else {
          localStorage.setItem('notificationCount', 0);
        }
      })
      .catch(error => {
      });
  }
  const getNotifications = () => {
    setIsNotificationLoading(true);
    axios.get(`${appConstant.API_URL}/notifications/notifications?page=1&limit=3`, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          setNotifications(res?.data?.data);
          notificationCount();
        } else {
          setNotifications([]);
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  const updateNotifications = (notificationId, url) => {
    setIsNotificationLoading(true);
    const notificationsData = {}
    axios.put(`${appConstant.API_URL}/notifications/notifications/${notificationId}`, notificationsData, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          nav(url)
        } else {
          setNotifications([]);
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  const updateAllNotifications = () => {
    setIsNotificationLoading(true);
    const notificationsData = {}
    axios.put(`${appConstant.API_URL}/bx_block_notifications/notification/mark_all_read`, notificationsData, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          notificationCount();
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  // const searchEquipment = (e) => {
  //   e.preventDefault();
  //   nav('/home/hire-equipment', {state:{search:inputValues.search}});
  // }
  const clearSearch = () => {
    window.location.href = '/home/hire-equipment';
  }
  return (
    <>
      <ScrollToTop />
      <header className='header fixed-top'>
        <nav className="navbar navbar-expand-lg navbar-light d-lg-flex d-none desktop">
          <div className='container header-content'>
            <div className='logo-container'>
              <div className={'logo'} onClick={() => { homePage() }} data-testid="head-title">Alyya</div>
            </div>
            <div className='account-container'>
              <div className='social-links'>
                <Link className="notice-link" to="/home/favourites"><img src="/images/favourite.png" alt="cart" /><span className='cart-count'>{favCount ? favCount : 0}</span></Link>
                <Link className="notice-link" to="/home/cart"><img src="/images/cart.png" alt="cart" /><span className='cart-count'>{cartsTotal ? cartsTotal : 0}</span></Link>
                {/*<Link className="notice-link" to="/home/message"><img src="/images/message.png" alt="message" /><span className='orange-dot'></span></Link>*/}
                <span className="dropdown notice-link">
                  <span className='cart-count'>{localStorage.getItem('notificationCount')}</span>
                  <button type="button" className="notice-link" data-bs-toggle="dropdown" onClick={() => { getNotifications() }}>
                    <img src="/images/notification.png" alt="notification" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {isNotificationLoading ? <LoadingSpinner /> :
                      <>
                        <li>
                          <div className="dropdown-item-text"><span>Notifications</span> <span className='links' style={{ cursor: 'pointer' }} onClick={() => { updateAllNotifications() }}>Mark all as read</span>
                          </div>
                        </li>
                        {notifications?.length > 0 && notifications?.map((result, i) =>
                          <>
                            <li key={i} onClick={() => { updateNotifications(result?.attributes?.id, result?.attributes?.url) }} style={{ cursor: 'pointer' }} className={!result?.attributes?.is_read ? 'unread' : ''}>
                              <div className="dropdown-item">
                                <div className='d-flex align-item-start'>

                                  <div className='position-relative'><img className='notice-img rounded-circle me-3' src={result?.attributes?.image ? result?.attributes?.image : '/images/userr.png'} alt="person" />
                                    {!result?.attributes?.is_read && <span className='green-dot'></span>}
                                  </div>
                                  <div className='flex-grow-1'>
                                    <div className='d-flex justify-content-between align-item-center'><span
                                      className='name' style={{ textTransform: 'capitalize', width: '60%' }}>{result?.attributes?.full_name}</span><span className='time'>{Moment(result?.attributes?.created_at).startOf('minutes').fromNow()}</span></div>
                                    <div className='time'>{result?.attributes?.contents}</div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        )}
                      </>
                    }
                    <li>
                      <div className='dropdown-item-text justify-content-center'><Link className='links' to="/home/notification" style={{ cursor: 'pointer' }}>See all notifications</Link></div>
                    </li>
                  </ul>
                </span>
                <div className="btn-group">
                  <span className="profile-img dropdown-toggle" data-bs-toggle="dropdown">{localStorage.getItem('image') ? <img src={localStorage.getItem('image')} alt="profile" /> : <img src="/images/userr.png" alt="profile" />}</span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="/home/account" className="dropdown-item">My Account</Link>
                    <Link to="/home/orders" className="dropdown-item">My Orders</Link>
                    <Link to="/logout" className="dropdown-item">Logout</Link>
                  </div>
                </div>
              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "1" ? 'active' : '')} to="/home/hire-equipment">Hire Equipment</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "2" ? 'active' : '')} to="/home/list-equipment">List Equipment</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "3" ? 'active' : '')} to="/home/about-us">About Us</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "4" ? 'active' : '')} to="/home/contact-us">Contact Us</Link>
                  </li>
                </ul>
                <form action={'/home/hire-equipment'} className="d-flex searchBox">
                  <input type="text" name="s" value={inputValues.s} onChange={(e) => handleChange(e)} placeholder="Search for equipment" />
                  {inputValues?.s?.length > 0 && <button className="search clear-search" type="button" onClick={() => { clearSearch() }}>&times;</button>}
                  <button className="search" type="submit"><img src="/images/search.png" alt="search" /></button>
                </form>
              </div>
            </div>
          </div>
        </nav>

        <nav className="navbar d-lg-none navbar-light tablets-mobile">
          <div className='container header-content'>
            <div className='logo-container'>
              <Link to="/home/hire-equipment" className="logo">Alyya</Link>
            </div>
            <div className='text-end'>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className='social-links'>
                <Link className="notice-link" to="home/favorites"><img src="/images/favourite.png" alt="cart" /><span className='cart-count'>{favCount ? favCount : 0}</span></Link>
                <Link className="notice-link" to="home/cart"><img src="/images/cart.png" alt="cart" /><span className='cart-count'>{cartsTotal ? cartsTotal : 0}</span></Link>
                {/*<Link className="notice-link" to="/home/message"><img src="/images/message.png" alt="message" /><span className='orange-dot'></span></Link>*/}
                <span className="dropdown notice-link">
                  <span className='cart-count'>{localStorage.getItem('notificationCount')}</span>
                  <button type="button" className="notice-link" data-bs-toggle="dropdown" onClick={() => { getNotifications() }}>
                    <img src="/images/notification.png" alt="notification" />
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {isNotificationLoading ? <LoadingSpinner /> :
                      <>
                        <li>
                          <div className="dropdown-item-text"><span>Notifications</span> <span className='links' style={{ cursor: 'pointer' }} onClick={() => { updateAllNotifications() }}>Mark all as read</span>
                          </div>
                        </li>
                        {notifications?.length > 0 && notifications?.map((result, i) =>
                          <>
                            <li key={i} onClick={() => { updateNotifications(result?.attributes?.id, result?.attributes?.url) }} style={{ cursor: 'pointer' }} className={!result?.attributes?.is_read ? 'unread' : ''}>
                              <div className="dropdown-item">
                                <div className='d-flex align-item-start'>
                                  <div className='position-relative'><img className='notice-img rounded-circle me-3' src={result?.attributes?.image ? result?.attributes?.image : '/images/userr.png'} alt="person" />
                                    {!result?.attributes?.is_read && <span className='green-dot'></span>}
                                  </div>
                                  <div className='flex-grow-1'>
                                    <div className='d-flex justify-content-between align-item-center'><span
                                      className='name' style={{ textTransform: 'capitalize', width: '60%' }}>{result?.attributes?.full_name}</span><span className='time'>{Moment(result?.attributes?.created_at).startOf('minutes').fromNow()}</span></div>
                                    <div className='time'>{result?.attributes?.contents}</div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        )}
                      </>
                    }
                    <li>
                      <div className='dropdown-item-text justify-content-center'><Link className='links' to="/home/notification" style={{ cursor: 'pointer' }}>See all notifications</Link></div>
                    </li>
                  </ul>
                </span>
                <div className="btn-group">
                  <span className="profile-img dropdown-toggle" data-bs-toggle="dropdown">{localStorage.getItem('image') ? <img src={localStorage.getItem('image')} alt="profile" /> : <img src="/images/userr.png" alt="profile" />}</span>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="/home/account" className="dropdown-item">My Account</Link>
                    <Link to="/home/orders" className="dropdown-item">My Orders</Link>
                    <Link to="/logout" className="dropdown-item">Logout</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className={"nav-link " + (props.id === "1" ? 'active' : '')} to="/hire-equipment">Hire Equipment</Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link " + (props.id === "2" ? 'active' : '')} to="/list-equipment">List Equipment</Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link " + (props.id === "3" ? 'active' : '')} to="/about-us">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className={"nav-link " + (props.id === "4" ? 'active' : '')} to="/contact-us">Contact Us</Link>
                </li>
              </ul>
              <form action={'/home/hire-equipment'} className="searchBox">
                <input type="text" name="s" value={inputValues.s} onChange={(e) => handleChange(e)} placeholder="Search for equipment" />
                <button className="search" type="submit" ><img src="/images/search.png" alt="search" /></button>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
};

export default HireHeader;
