import React, {useEffect, useState} from 'react';
import Header from './Header';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';
import Moment from 'moment';
import Pagination from '../shared/Pagination';

function AdminNotification() {
    const nav = useNavigate();
    let [notifications, setNotifications] = useState([]);
    let [notificationsData, setNotificationsData] = useState([]);
    let [isNotificationLoading, setIsNotificationLoading] = useState(false);
    let [notificationPaginationPages, setNotificationPaginationPages] = useState(false);
    useEffect( ()=> {
        notificationCount();
        getNotifications(1);
    }, []);
    const params = {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": '*',
            "token": localStorage.getItem('token')
        }
    };
    const notificationCount = () => {
        axios.get(`${appConstant.API_URL}/bx_block_notifications/notification/total_count`, params)
            .then(res => {
                if (res?.data?.status === 200) {
                    localStorage.setItem('notificationCount', res?.data?.total_count);
                }else {
                    localStorage.setItem('notificationCount', 0);
                }
            })
            .catch(error => {
            });
    }
    const getNotifications = (page) => {
        setIsNotificationLoading(true);
        axios.get(`${appConstant.API_URL}/notifications/notifications?page=${page}&limit=20`, params)
            .then(res => {
                setIsNotificationLoading(false);
                window.scrollTo(0, 0);
                if (res?.data?.status === 200) {
                    let items = [];
                    let today = false;
                    let yesterday = false;
                    let others = false;
                    if(res?.data?.data?.length > 0){
                        for (var i = 0; i < res?.data?.data?.length; i++){
                            let item = res?.data?.data[i];
                            if(item.attributes?.received_at === 'Today' && today === false ){
                                item.typeStatus = true;
                                today = true;
                            }else if(item.attributes?.received_at === 'Yesterday' && yesterday === false ){
                                item.typeStatus = true;
                                yesterday = true;
                            } else if(item.attributes?.received_at === 'Other' && others === false ){
                                item.typeStatus = true;
                                others = true;
                            } else{
                                item.typeStatus = false;
                            }
                            items.push(item);
                        }
                    }else{
                        setNotifications([]);
                    }
                    setNotifications(res.data);
                    setNotificationsData(items);
                    const row = [];
                    if(parseInt(res?.data?.total_pages) > 0){
                        for (var i = 1; i <= parseInt(res?.data?.total_pages); i++) {
                            row.push(i);
                        }
                    }
                    setNotificationPaginationPages(row);
                    notificationCount();
                }else {
                    setNotifications([]);
                }
            })
            .catch(error => {
                setIsNotificationLoading(false);
                setNotifications([]);
            });
    }
    const updateNotifications = (notificationId, url) => {
        setIsNotificationLoading(true);
        const notificationsData = {}
        axios.put(`${appConstant.API_URL}/notifications/notifications/${notificationId}`, notificationsData, params)
            .then(res => {
                setIsNotificationLoading(false);
                if (res?.data?.status === 200) {
                    nav(url);
                }
            })
            .catch(error => {
                setIsNotificationLoading(false);
            });
    }
    const updateAllNotifications = () => {
        setIsNotificationLoading(true);
        const notificationsData = {}
        axios.put(`${appConstant.API_URL}/bx_block_notifications/notification/mark_all_read`, notificationsData, params)
            .then(res => {
                setIsNotificationLoading(false);
                if (res?.data?.status === 200) {
                    getNotifications(1);
                    notificationCount();
                }
            })
            .catch(error => {
                setIsNotificationLoading(false);
            });
    }
    return (
        <>
            <Header/>
            <div className='filter-blank'></div>
            <div className='notification home-content'>
                <div className='container'>
                    <div className='breadcrumbs'>
                        <div><Link to="/dashboard">Dashboard / </Link> <strong>Notification</strong></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <h6 className='heading'>Notifications</h6>
                        <button className='mark-btn' onClick={() => {updateAllNotifications()}}>Mark all as read</button>
                    </div>
                    {isNotificationLoading ? <LoadingSpinner/> :
                        <>
                            <p className='content mb-4'>You've got {localStorage.getItem('notificationCount')} unread notification(s)</p>
                            <hr/>
                            {notificationsData?.length > 0 && notificationsData?.map((result, i) => {
                                return <>
                                    {result?.typeStatus && <h6 className='date my-4'>{result?.attributes?.received_at}</h6>}
                                    <div className={!result?.attributes?.is_read ? 'single-notification unread' : 'single-notification'} key={i} onClick={() => {updateNotifications(result?.attributes?.id, result?.attributes?.app_url)}} >
                                    <img className='profile-img' src={result?.attributes?.image ? result?.attributes?.image : '/images/userr.png'} alt="profile"/>
                                        <div>
                                            <div className='name'>{result?.attributes?.full_name} <span className='time' title={Moment(result?.attributes?.created_at).format('LLLL Z')}>{Moment(result?.attributes?.created_at).startOf('minutes').fromNow()}</span></div>
                                            <div className='order'><span className="links">{result?.attributes?.contents}</span></div>
                                        </div>
                                    </div>
                                </>
                            })}
                            {notifications?.data?.length === 0 && <h3>Not found.</h3>}
                            {notifications?.data?.length > 0 &&
                                <Pagination
                                className="pagination-bar"
                                currentPage={notifications?.current_page}
                                totalCount={notifications?.total_pages}
                                pageSize={1}
                                onPageChange={page => getNotifications(page)}
                                />
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
};

export default AdminNotification;