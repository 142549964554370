import React, { useRef, useEffect, useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
// import Modal from '@material-ui/core/Modal';/*  */
import axios from 'axios';
import appConstant from '../../shared/constant/constant.json';
import Moment from 'moment';
import {Button,DialogContentText,DialogActions,Dialog,DialogTitle,DialogContent} from '@material-ui/core'
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../shared/Loader';

function QuoteRequest() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getQuotes('pending', 1);
    getQuotes('sent', 1);
    getQuotes('approved,declined,cancelled', 1);
    getLocations();
  }, []);
  const [pendingQuotes, setPendingQuotes] = React.useState([]);
  const [sentQuotes, setSentQuotes] = React.useState([]);
  const [approvedQuotes, setApprovedQuotes] = React.useState([]);
  const [pendingPaginationPages, setPendingPaginationPages] = React.useState([]);
  const [sentPaginationPages, setSentPaginationPages] = React.useState([]);
  const [approvedPaginationPages, setApprovedPaginationPages] = React.useState([]);
  const [popupIndex, setpopupIndex] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const [resultMessage, setResultMessage] = React.useState('');
  const [customerName, setCustomerName] = React.useState('')
  const [baseLocation, setBaseLocation] = React.useState('')
  const [requestedLocation, setRequestedLocation] = React.useState('')
  const [amountType, setAmountType] = React.useState([]);
  const [open, setOpen] = useState(false)

  const addQuantity = () => {
    let data = { ...inputValues };
    const newValue = parseInt(inputValues?.quantity) + 1;
    data.quantity = newValue;
    setInputValue(data);
  }
  const removeQuantity = () => {
    let data = { ...inputValues };
    if (data.quantity > 1) {
      const newValue = parseInt(inputValues?.quantity) - 1;
      data.quantity = newValue;
      setInputValue(data);
    }
  }

  const changeCartValue = (e) => {
    const { name, value } = e.target;
    if (value) {
      setInputValue({ ...inputValues, [name]: value });
    } else {
      setInputValue({ ...inputValues, [name]: 1 });
    }
  }
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const orderSummarydata = async (order_id) => {
    try {
      const response = await axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/order_summary?id=1939`, params);
      if (response.data.status === 200) {
        const url = response.data.data.attributes.upload_transcript;
        const fileResponse = await axios.get(url, { responseType: 'blob' });
        const contentType = fileResponse.headers['content-type'];
        const contentDisposition = fileResponse.headers['content-disposition'];
        let filename = 'transcript';
        if (contentDisposition) {
          const decodedContentDisposition = decodeURI(contentDisposition);
          const match = decodedContentDisposition.match(/filename="?([^"]*)"?/);
          if (match && match.length > 1) {
            filename = match[1];
          }
        }
        filename = filename.replace(/[^a-zA-Z0-9_. -]/g, '');
        const downloadUrl = window.URL.createObjectURL(new Blob([fileResponse.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {

      }
    } catch (error) {

    }
  };
  const getQuotes = (type, page) => {
    setIsLoading(true);
    axios.get(`${appConstant.API_URL}/bx_block_order_management/quotes_listing?status=${type}&page=${page}&limit=20`, params)
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          if (type == 'pending') {
            setPendingQuotes(res.data);
            const row = [];
            if (parseInt(res?.data?.total_pages) > 0) {
              for (var i = 1; i <= parseInt(res?.data?.total_pages); i++) {
                row.push(i);
              }
            }
            setPendingPaginationPages(row);
          } else if (type == 'sent') {
            setSentQuotes(res.data);
            const row = [];
            if (parseInt(res?.data?.total_pages) > 0) {
              for (var i = 1; i <= parseInt(res?.data?.total_pages); i++) {
                row.push(i);
              }
            }
            setSentPaginationPages(row);
          } else {
            setApprovedQuotes(res.data);
            const row = [];
            if (parseInt(res?.data?.total_pages) > 0) {
              for (var i = 1; i <= parseInt(res?.data?.total_pages); i++) {
                row.push(i);
              }
            }
            setApprovedPaginationPages(row);
          }
        } else {
          if (type == 'pending') {
            setPendingQuotes([]);
            const row = [];
            setPendingPaginationPages(row);
          } else if (type == 'sent') {
            setSentQuotes([]);
            const row = [];
            setSentPaginationPages(row);
          } else {
            setApprovedQuotes([]);
            const row = [];
            setApprovedPaginationPages(row);
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  }
  const openQuotePopup = (index) => {
    setpopupIndex(index);
    const preStartDate = pendingQuotes?.data[index]?.attributes?.start_date ? pendingQuotes?.data[index]?.attributes?.start_date : '';
    const preEndDate = pendingQuotes?.data[index]?.attributes?.end_date ? pendingQuotes?.data[index]?.attributes?.end_date : '';
    let amountTypeData = '';
    let amount = '';
    const zoneId = pendingQuotes?.data[index]?.attributes?.base_location_id ? pendingQuotes?.data[index]?.attributes?.base_location_id : '';
    const baseLocationId = pendingQuotes?.data[index]?.attributes?.default_location_id ? pendingQuotes?.data[index]?.attributes?.default_location_id : '';
    const zoneName = locations.find(({ item, id }) => id === zoneId)
    const baseLocation = locations.find(({ item, id }) => id === baseLocationId)
    const customername = pendingQuotes?.data[index]?.attributes?.customer?.full_name
    setRequestedLocation(zoneName.name)
    setBaseLocation(baseLocation.name)
    setCustomerName(customername)

    if (pendingQuotes?.data[index]?.attributes?.start_date && pendingQuotes?.data[index]?.attributes?.end_date) {
      const quoteStartDate = Moment(pendingQuotes?.data[index]?.attributes?.start_date, 'DD/MM/YYYY');
      const quoteEndtDate = Moment(pendingQuotes?.data[index]?.attributes?.end_date, 'DD/MM/YYYY');
      const diff = quoteEndtDate.diff(quoteStartDate, 'days');
      if (parseInt(diff) < 7) {
        amountTypeData = 'per day';
        amount = pendingQuotes?.data[index]?.attributes?.price_per_day ? pendingQuotes?.data[index]?.attributes?.price_per_day : '';
      } else if (parseInt(diff) < 30) {
        amountTypeData = 'per week';
        amount = pendingQuotes?.data[index]?.attributes?.price_per_week ? pendingQuotes?.data[index]?.attributes?.price_per_week : '';
      } else {
        amountTypeData = 'per month';
        amount = pendingQuotes?.data[index]?.attributes?.price_per_month ? pendingQuotes?.data[index]?.attributes?.price_per_month : '';
      }
    }
    const quantity = pendingQuotes?.data[index]?.attributes?.quantity ? pendingQuotes?.data[index]?.attributes?.quantity : 1;
    const fillableData = {
      aed: amount,
      time: amountTypeData,
      mobilisation: 'yes',
      startDate: preStartDate,
      endDate: preEndDate,
      location: zoneName.name,
      terms: '',
      quantity: quantity
    }
    setInputValue(fillableData);
  }

  const [inputValues, setInputValue] = useState({
    aed: '',
    time: '',
    mobilisation: 'yes',
    startDate: '',
    endDate: '',
    location: '',
    terms: '',
    quantity: ''
  });
  const [validation, setValidation] = useState({
    aed: '',
    time: '',
    mobilisation: '',
    startDate: '',
    endDate: '',
    location: '',
    terms: '',
    quantity: ''
  });
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));
    const currentDate = Moment().format('YYYY-MM-DD');
    if (!inputValues.aed) {
      errors.aed = 'Amount is required';
    }
    else if (!Number.isFinite(Number(inputValues.aed))) {
      errors.aed = 'Please enter valid amount';
    }
    else {
      errors.aed = '';
    }
    //time validation

    //mobilisation validation
    if (!inputValues.mobilisation) {
      errors.mobilisation = 'Mobilisation is required';
    } else {
      errors.mobilisation = '';
    }
    //startDate validation
    if (!inputValues.startDate) {
      errors.startDate = 'Start Date is required';
    } else if (!Moment('2099-12-31').isAfter(inputValues.startDate)) {
      errors.startDate = 'Invalid date.';
    } else if (!Moment(inputValues.startDate).isSameOrAfter(currentDate)) {
      errors.startDate = 'Should be same or bigger than current date';
    } else {
      errors.startDate = '';
    }
    //endDate validation
    if (!inputValues.endDate) {
      errors.endDate = 'End Date is required';
    } else if (!Moment('2099-12-31').isAfter(inputValues.endDate)) {
      errors.endDate = 'Invalid date.';
    } else if (!Moment(inputValues.endDate).isSameOrAfter(inputValues.startDate)) {
      errors.endDate = 'Should be bigger than start date';
    } else {
      errors.endDate = '';
    }
    //location validation
    if (!inputValues.location) {
      errors.location = 'Location is required';
    } else {
      errors.location = '';
    }

    setValidation(errors);
    if (errors.aed === '' && errors.mobilisation === '' && errors.startDate === '' && errors.endDate === '' && errors.location === '' && errors.quantity === '') {
      return true;
    } else {
      return false;
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const changeAmountType = (e) => {
    const { name, value } = e.target;
    const data = inputValues;
    if (name === 'endDate') {
      data.endDate = value;
    } else {
      data.startDate = value;
    }
    const quoteStartDate = Moment(data.startDate, 'YYYY-MM-DD');
    const quoteEndtDate = Moment(data.endDate, 'YYYY-MM-DD');
    const diff = quoteEndtDate.diff(quoteStartDate, 'days');
    let amountTypeData = '';
    if (parseInt(diff) < 7) {
      amountTypeData = "per day";
    } else if (parseInt(diff) < 30) {
      amountTypeData = "per week";
    } else {
      amountTypeData = "per month";
    }
    data.time = amountTypeData;
    setInputValue({ ...inputValues, ...data });
  }

  const handleMobilisation = (quoteId, perDayPrice, perWeekPrice, perMonthPrice, baseLocationId, defaultLocationId) => {

    const isSuccess = checkValidation();
    if (isSuccess) {
      if (defaultLocationId !== baseLocationId) {
        setOpen(true)
      } else {
        updateQuotes(quoteId, perDayPrice, perWeekPrice, perMonthPrice, baseLocationId, defaultLocationId);
      }

    }
  }
  const getLocations = () => {
    axios.get(`${appConstant.API_URL}/bx_block_categories/fetch_locations`, params)
      .then(res => {
        if (res.data.status === 200) {
          setLocations(res.data.locations);
        } else {
        }
      })
      .catch(error => {
      });
  }

  const handleApprove = async (orderid, quoteid) => {
    const payload = {
      order_id: orderid,
      quote_id: quoteid,
     payment_status_key:"Approved"
    }
    try {
      const response = await axios.put(`${appConstant.API_URL}/bx_block_order_management/update_status_paid`, payload, params);
      if (response.status === 200) {
        getQuotes('approved,declined,cancelled', 1);

      }
 } catch (error) {
       console.log(error,"eee")
      // Handle error
    }

  }

const handleDecline = async(orderid, quoteid) =>{
const payload = {
      order_id: orderid,
      quote_id: quoteid,
     payment_status_key:"Declined"
    }
 try {
      const response = await axios.put(`${appConstant.API_URL}/bx_block_order_management/update_status_paid`, payload, params);
      if (response.status === 200) {
        getQuotes('approved,declined,cancelled', 1);

      }
 } catch (error) {
       console.log(error,"eee")
      // Handle error
    }
}


  const updateQuotes = (quoteId, perDayPrice, perWeekPrice, perMonthPrice, baseLocationId, defaultLocationId) => {
    setIsLoading(true);
    const updateQuoteData = {
      start_date: inputValues.startDate,
      end_date: inputValues.endDate,
      zone: inputValues.location,
      mobilisation: (inputValues.mobilisation === 'yes') ? true : false,
      price_per_day: (inputValues.time === 'per day') ? inputValues.aed : perDayPrice,
      price_per_week: (inputValues.time === 'per week') ? inputValues.aed : perWeekPrice,
      price_per_month: (inputValues.time === 'per month') ? inputValues.aed : perMonthPrice,
      notes: inputValues.terms,
      quantity: inputValues.quantity
    }

    axios.put(`${appConstant.API_URL}/bx_block_order_management/quotes/${quoteId}`, updateQuoteData, params)
      .then(res => {
        setIsLoading(false);
        if (res.data.status === 200) {
          window.location.reload();
          setResultMessage('Successfully sent.');
          setOpen(false)

        } else {
          setResultMessage(res.data.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleOkClick = () => {
    updateQuotes(pendingQuotes?.data[popupIndex]?.attributes?.id, pendingQuotes?.data[popupIndex]?.attributes?.price_per_day, pendingQuotes?.data[popupIndex]?.attributes?.price_per_week, pendingQuotes?.data[popupIndex]?.attributes?.price_per_month, pendingQuotes?.data[popupIndex]?.attributes?.base_location_id, pendingQuotes?.data[popupIndex]?.attributes?.default_location_id)
  }

  const formatCurrency = (value) => {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).replace(/^(\D+)/, '');
  };


  return (
    <>
      <Header />
      <div className='blank'></div>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-md-3'>
            <Sidebar id="2" />
          </div>
          <div className='col-md-9'>
            <div className='rightSide'>
              <div className='breadcrumbs'>
                <span className='active-page' data-testid="quote-request">Quote Request</span>
              </div>
              <ul className="nav nav-pills alyya-tabs">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="pill" href="#pending-quotes">Pending Quotes</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="pill" href="#quotes-send">Quote Sent</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="pill" href="#quote-approved">Quote Approved / Declined</a>
                </li>
              </ul>
              <div className="tab-content alyya-tabs-content">
                <div className="tab-pane container active" id="pending-quotes">
                  {isLoading ? <LoadingSpinner /> :
                    <div>
                      <div className="table-responsive alyya-table">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className='min-330'>Equipment & Capacity</th>
                              <th className='min-250'>Duration</th>
                              <th >Quantity</th>
                              <th className='min-180'>Action</th>
                            </tr>
                          </thead>
                          <tbody id={pendingQuotes?.data?.length}>
                            {pendingQuotes?.data?.length > 0 && pendingQuotes?.data?.map((result, i) =>
                              <>
                                <tr key={i}>
                                  <td className='min-330'>
                                    <div className='d-flex align-items-center'>
                                      <img src={result?.attributes?.image && result?.attributes?.image} className=""
                                        alt={result?.attributes?.equipment_name} />
                                      <div>
                                        <strong className='d-block'>{result?.attributes?.equipment_name}</strong>
                                        <span className='d-block mt-2'>{result?.attributes?.sub_category_name}</span>
                                        <span
                                          className='d-block mt-2'>{(result?.attributes?.capacity?.value) ? result?.attributes?.capacity.value : '0'} Capacity</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='min-250'>
                                    <div className='row m-0 p-0'>
                                      <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                      <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                    </div>
                                  </td>
                                  <td className="min-250" ><strong>{result?.attributes?.quantity}</strong></td>
                                  <td >
                                    <button className='table-success-button' type="button" data-bs-toggle="modal"
                                      data-bs-target="#sendQuotes" onClick={() => {
                                        openQuotePopup(i)
                                      }}>Send Quote
                                    </button>
                                  </td>
                                </tr>
                              </>
                            )}
                            {pendingQuotes?.length === 0 &&
                              <tr>
                                <th key='no' colSpan='4'>
                                  Not Found.
                                </th>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      {pendingQuotes?.data?.length > 0 &&
                        <ul className="pagination justify-content-between alyya-pagination">
                          {pendingQuotes?.current_page === 1 &&
                            <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                          {pendingQuotes?.current_page !== 1 && <li className="page-item" onClick={() => {
                            getQuotes('pending', pendingQuotes?.current_page - 1)
                          }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                          <div className='d-flex'>
                            {pendingPaginationPages?.length > 0 && pendingPaginationPages?.map((result, i) =>
                              <>
                                {result === pendingQuotes?.current_page &&
                                  <li className="page-item" key={'pendingPagination' + i}><a
                                    className="page-link active">{result}</a></li>}
                                {result !== pendingQuotes?.current_page &&
                                  <li className="page-item" key={'pendingPagination' + i}><a className="page-link"
                                    onClick={() => {
                                      getQuotes('pending', result)
                                    }}>{result}</a></li>}

                              </>
                            )}
                          </div>

                          {pendingQuotes?.current_page === pendingQuotes?.total_pages &&
                            <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                          {pendingQuotes?.current_page !== pendingQuotes?.total_pages &&
                            <li className="page-item" onClick={() => {
                              getQuotes('pending', pendingQuotes?.current_page + 1)
                            }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                        </ul>
                      }
                    </div>
                  }
                </div>
                <div className="tab-pane container fade" id="quotes-send">
                  {isLoading ? <LoadingSpinner /> :
                    <div>
                      <div className="table-responsive alyya-table">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className='min-330'>Equipment & Capacity</th>
                              <th className='min-250'>Duration</th>
                              <th>Quantity</th>
                              <th className='min-180'>Quote Sent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sentQuotes?.data?.length > 0 && sentQuotes?.data?.map((result, i) =>
                              <>
                                <tr key={i}>
                                  <td className='min-330'>
                                    <div className='d-flex align-items-center'>
                                      <img src={result?.attributes?.image && result?.attributes?.image} className=""
                                        alt={result?.attributes?.equipment_name} />
                                      <div>
                                        <strong className='d-block'>{result?.attributes?.equipment_name}</strong>
                                        <span
                                          className='d-block mt-2'>{result?.attributes?.sub_category_name}</span>
                                        <span
                                          className='d-block mt-2'>{result?.attributes?.capacity?.value} Capacity</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='min-250' width={'250px'}>
                                    <div className='row m-0 p-0'>
                                      <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                      <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                    </div>
                                  </td>
                                  <td className='min-250'><strong>{result?.attributes?.quantity}</strong></td>

                                  <td className='min-250'>
                                    <strong className=''>
                                      <div className='d-inline-block mx-2'>SAR</div>
                                      {result?.attributes?.quote_amount !== undefined &&
                                        result?.attributes?.quote_amount !== '' ? (
                                        <>
                                          {result?.attributes?.quote_amount
                                            .split(/([0-9.,]+)/)
                                            .map((part, index) =>
                                              index % 2 === 0 ? (
                                                part
                                              ) : (
                                                <span key={index}>
                                                  {Number(part).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })}
                                                </span>
                                              )
                                            )}
                                        </>
                                      ) : (
                                        'N/A'
                                      )}
                                    </strong>
                                  </td>
                                </tr>
                              </>
                            )}
                            {sentQuotes?.length === 0 &&
                              <tr>
                                <th key='no' colSpan='4'>
                                  Not Found.
                                </th>
                              </tr>
                            }
                          </tbody>

                        </table>
                      </div>
                      {sentQuotes?.data?.length > 0 &&
                        <ul className="pagination justify-content-between alyya-pagination">
                          {sentQuotes?.current_page === 1 &&
                            <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a>
                            </li>}
                          {sentQuotes?.current_page !== 1 && <li className="page-item" onClick={() => {
                            getQuotes('sent', sentQuotes?.current_page - 1)
                          }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                          <div className='d-flex'>
                            {sentPaginationPages?.length > 0 && sentPaginationPages?.map((result, i) =>
                              <>
                                {result === sentQuotes?.current_page &&
                                  <li className="page-item" key={'sentPagination' + i}><a
                                    className="page-link active">{result}</a></li>}
                                {result !== sentQuotes?.current_page &&
                                  <li className="page-item" key={'sentPagination' + i}><a className="page-link"
                                    onClick={() => {
                                      getQuotes('sent', result)
                                    }}>{result}</a></li>}

                              </>
                            )}
                          </div>

                          {sentQuotes?.current_page === sentQuotes?.total_pages &&
                            <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                          {sentQuotes?.current_page !== sentQuotes?.total_pages &&
                            <li className="page-item" onClick={() => {
                              getQuotes('sent', sentQuotes?.current_page + 1)
                            }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                        </ul>
                      }
                    </div>
                  }
                </div>
                <div className="tab-pane container fade" id="quote-approved">
                  {isLoading ? <LoadingSpinner /> :
                    <div>
                      <div className="table-responsive alyya-table border-0">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th className='min-330'>Equipment & Capacity</th>
                              <th className='min-210'>Duration</th>
                              <th className='min-150 '>Quantity</th>
                              <th className='min-180 '>Quote Sent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {approvedQuotes?.data?.length > 0 && approvedQuotes?.data?.map((result, i) =>
                              <>
                                <div className='mb-4'></div>
                                <tr key={i} style={{ border: 'rgba(120, 120, 128, 0.36) solid 2px' }}>
                                  <td className='min-330'>
                                    <div className='d-flex align-items-center'>
                                      <img src={result?.attributes?.image && result?.attributes?.image}
                                        className="" alt={result?.attributes?.equipment_name} />
                                      <div>
                                        <strong
                                          className='d-block'>{result?.attributes?.equipment_name}</strong>
                                        <span
                                          className='d-block mt-2'>{result?.attributes?.sub_category_name}</span>
                                        <span
                                          className='d-block mt-2'>{(result?.attributes?.capacity?.value) ? result?.attributes?.capacity?.value : '0'} Capacity</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td className='min-250'>
                                    <div className='row m-0 p-0'>
                                      <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                      <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                                      <div className='col-6 px-0'><strong>{Moment(result?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                    </div>
                                  </td>
                                  <td className='min-250'><strong>{result?.attributes?.quantity}</strong></td>
                                  <td className='min-250' >
                                    <strong className='quote-value' style={{ whiteSpace: 'nowrap', fontSize: '1.1rem' }}><span className='d-inline-block mx-2'>SAR</span>
                                      {result?.attributes?.order.total_amount !== undefined ? formatCurrency(result?.attributes?.order.total_amount) : 0}
                                    </strong>
                                    <div className="pt-3" style={{ whiteSpace: 'nowrap' }}>{(result?.attributes?.status === 'Approved' && result?.attributes?.upload_transcript) ? <a href={result?.attributes?.upload_transcript}><span className='links d-block mb-2' style={{ cursor: 'pointer' }} onClick={() => orderSummarydata(result?.attributes?.id)}><img className='invoice' style={{ width: '25px', height: '21px' }} src="/images/invoice-icon.png" alt="invoice" /> &nbsp;Download Transcript</span></a> : ''}</div>
                                    {result?.attributes?.upload_transcript && <>
                                      <div style={{ display: "flex", gap: "5px" }}>
                                        <button data-testid="approved-quote" className='table-success-button mb-2 d-block approve-payment' type="button" disabled={result?.attributes?.order?.payment_status === "PAID" ||result?.attributes?.order?.payment_decline }
                                          onClick={() => handleApprove(result?.attributes?.order_id,result?.attributes?.id)}>{result?.attributes?.order?.payment_status === "PAID" ?"Approved":"Approve"}</button>
                                        <button className='table-red-button mb-2 d-block decline-payment' type="button" disabled={result?.attributes?.order?.payment_decline || result?.attributes?.order?.payment_status === "PAID"}
                                        onClick={() => handleDecline(result?.attributes?.order_id,result?.attributes?.id)} >{result?.attributes?.order?.payment_decline?"Declined":"Decline"}</button>
                                      </div>
                                    </>}
                                  </td>

                                </tr>
                                <tr style={{ border: 'rgba(120, 120, 128, 0.36) solid 2px' }}>
                                  <td className='min-330'>
                                    Order status <span className={result?.attributes?.order?.order_status === 'cancelled' ? 'rejected' : (result?.attributes?.order?.order_status === 'confirmed' ? 'success' : 'warning')}
                                    >{result?.attributes?.status.toUpperCase()}</span>
                                  </td>
                                  <td className='min-210' colSpan="2">
                                    Payment status <span className='payment-status'
                                      style={{ textTransform: 'capitalize' }} data-testid="payment-status">{result?.attributes?.order?.payment_status}</span>
                                  </td>
                                  <td className='min-180 text-center'>
                                    <Link to={'/orders/order-summary/' + result?.attributes?.order_id}
                                      className='order-summary'>order Summary</Link>
                                  </td>
                                </tr>

                              </>
                            )}
                            {approvedQuotes?.length === 0 &&
                              <tr>
                                <th key='no' colSpan='4'>
                                  Not Found.
                                </th>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      {approvedQuotes?.data?.length > 0 &&
                        <ul className="pagination justify-content-between alyya-pagination">
                          {approvedQuotes?.current_page === 1 &&
                            <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a>
                            </li>}
                          {approvedQuotes?.current_page !== 1 && <li className="page-item" onClick={() => {
                            getQuotes('approved,declined,cancelled', approvedQuotes?.current_page - 1)
                          }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                          <div className='d-flex'>
                            {approvedPaginationPages?.length > 0 && approvedPaginationPages?.map((result, i) =>
                              <>
                                {result === approvedQuotes?.current_page &&
                                  <li className="page-item" key={'approvedPagination' + i}><a
                                    className="page-link active">{result}</a></li>}
                                {result !== approvedQuotes?.current_page &&
                                  <li className="page-item" key={'approvedPagination' + i}><a className="page-link"
                                    onClick={() => {
                                      getQuotes('approved,declined,cancelled', result)
                                    }}>{result}</a></li>}

                              </>
                            )}
                          </div>

                          {approvedQuotes?.current_page === approvedQuotes?.total_pages &&
                            <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                          {approvedQuotes?.current_page !== approvedQuotes?.total_pages &&
                            <li className="page-item" onClick={() => {
                              getQuotes('approved,declined,cancelled', approvedQuotes?.current_page + 1)
                            }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                        </ul>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}



        <div className="modal back-shadow" id="sendQuotes">
          <div className="modal-dialog modal-dialog-centered modal-width">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Provide Quote</h4>
                <button type="button" className="close-button" data-bs-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">

                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title" style={{ color: '#ff8d27' }}>Warn</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {`Your Equipment is located at ${baseLocation} but ${customerName} wants at location ${requestedLocation} do you want to continue?`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} style={{ color: "#ff8d27" }} variant="outlined">
                      Cancel
                    </Button>
                    <Button onClick={handleOkClick} style={{ color: 'black', backgroundColor: '#ff8d27' }} variant="outlined">
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                <div className="table-responsive alyya-table">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th className='min-250'>Equipment & Capacity</th>
                        <th className='min-180'>Category/Capicity</th>
                        <th className='min-180'>Duration</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='min-250'>
                          <div className='d-flex align-items-center'>
                            <img src={(pendingQuotes?.data?.length > 0) ? pendingQuotes?.data[popupIndex]?.attributes?.image : ''} className="" alt={(pendingQuotes?.data?.length > 0) ? pendingQuotes?.data[popupIndex]?.attributes?.equipment_name : ''} />
                            <div>
                              <strong className='d-block'>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.equipment_name}</strong>
                            </div>
                          </div>
                        </td>
                        <td className='min-180'>
                          <span className='d-block mt-2'>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.sub_category_name}</span>
                          <span className='d-block mt-2'>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.capacity?.value} Capacity</span >
                        </td>
                        <td className='min-250'>
                          <div className='row m-0 p-0'>
                            <div className='col-6 px-0'><div className='mb-2'>Start Date</div></div>
                            <div className='col-6 px-0'><strong>{(pendingQuotes?.data?.length > 0) && Moment(pendingQuotes?.data[popupIndex]?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                            <div className='col-6 px-0'><div className='mb-2'>End Date</div></div>
                            <div className='col-6 px-0'><strong>{(pendingQuotes?.data?.length > 0) && Moment(pendingQuotes?.data[popupIndex]?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                          </div>
                        </td>
                        <td>
                          <strong>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.quantity}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td className='min-250'><strong><small className='mb-1 d-inline-block'>Requestor</small></strong><br /><span>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.customer?.full_name}</span></td>
                        <td className='min-250'><strong><small className='mb-1 d-inline-block'>Phone Number</small></strong><br /><span>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.customer?.contact}</span></td>
                        <td className='min-250' colSpan={'2'}><strong><small className='mb-1 d-inline-block'>Email ID</small></strong><br /><span>{(pendingQuotes?.data?.length > 0) && pendingQuotes?.data[popupIndex]?.attributes?.customer?.email}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <form className='filters'>
                  {resultMessage && <p className='formErrors'>{resultMessage}</p>}
                  <div className='row'>
                    <div className='col-sm-12 mb-3'>
                      <div className='price-box'>
                        <div>
                          <label>SAR</label> <input  type="text" name="aed" value={inputValues.aed ? formatCurrency(inputValues.aed) : 0}  />
                        </div>
                        <div>
                          <span>|</span>
                          <input type="hidden" name="time" disabled={true} value={inputValues.time} onChange={(e) => handleChange(e)} /> {inputValues.time}
                        </div>
                      </div>
                      {validation.aed && <p className='formErrors'>{validation.aed}</p>}
                    </div>
                    <div className='col-sm-12 mb-3'>
                      <label htmlFor="mobilisation" className="label d-block mb-2">Quantity</label>
                      <div className='quantity-change'>
                        <button className='input-number-change' type='button' onClick={() => removeQuantity()}>-</button>
                        <input className="input-number" name="quantity" disabled={true} onChange={(e) => changeCartValue(e)} type="text" value={inputValues?.quantity ? inputValues?.quantity : 1} />
                        <button className='input-number-change' type='button' onClick={() => addQuantity()}>+</button>
                      </div>
                    </div>
                    <div className='col-sm-12 mb-3'>
                      <label htmlFor="mobilisation" className="label d-block mb-2">Mobilisation provided?</label>
                      <label className="radio-type">Yes
                        <input type="radio" checked={inputValues.mobilisation === 'yes'} id="yes" name="mobilisation" value="yes" onChange={(e) => handleChange(e)} />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-type">No
                        <input type="radio" id="no" checked={inputValues.mobilisation !== 'yes'} name="mobilisation" value="no" onChange={(e) => handleChange(e)} />
                        <span className="checkmark"></span>
                      </label>
                      {validation.mobilisation && <p className='formErrors'>{validation.mobilisation}</p>}
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="date" className="label d-block">Dates</label>
                    </div>
                    <div className='col-sm-6 mb-3'>
                      <input type="date" className="inputs d-block date w-100 mb-2" name="startDate" id="startDate" min={Moment().format('YYYY-MM-DD')} placeholder="From" value={inputValues.startDate} onChange={(e) => changeAmountType(e)} onFocus={(e) => e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                      {validation.startDate && <p className='formErrors'>{validation.startDate}</p>}
                    </div>
                    <div className='col-sm-6 mb-3'>
                      <input type="date" className="inputs d-block date w-100 mb-2" name="endDate" id="endDate" min={Moment(inputValues.startDate).format('YYYY-MM-DD')} placeholder="To" value={inputValues.endDate} onChange={(e) => changeAmountType(e)} onFocus={(e) => e.target.blur()} onKeyPress={(e) => e.preventDefault()} onKeyDown={(e) => e.preventDefault()} />
                      {validation.endDate && <p className='formErrors'>{validation.endDate}</p>}
                    </div>
                    <div className='col-sm-12 mb-2'>
                      <label htmlFor="location" className="label d-block">Zone/ Location</label>
                    </div>
                    <div className='col-sm-12 mb-3'>
                      <input type="text" className="inputs d-block w-100" id="location" name="location" value={inputValues.location} />
                    </div>
                    <div className='col-sm-12 mb-3'>
                      <label htmlFor="terms" className="label d-block mb-2">Terms/Notes</label>
                      <textarea className='inputs d-block w-100 mb-2' rows={4} name="terms" value={inputValues.terms} onChange={(e) => handleChange(e)} style={{ resize: "none" }}></textarea>
                      {validation.terms && <p className='formErrors'>{validation.terms}</p>}
                    </div>
                    <div className='button-group justify-content-center'>
                      {isLoading ? <button className="filled-button" disabled={true}>Submit Quote <img src='/images/default.gif' width={'16px'} height={'16px'} /></button> :
                        <button className='filled-button' type="button" onClick={() => {
                          handleMobilisation(pendingQuotes?.data[popupIndex]?.attributes?.id, pendingQuotes?.data[popupIndex]?.attributes?.price_per_day, pendingQuotes?.data[popupIndex]?.attributes?.price_per_week, pendingQuotes?.data[popupIndex]?.attributes?.price_per_month, pendingQuotes?.data[popupIndex]?.attributes?.base_location_id, pendingQuotes?.data[popupIndex]?.attributes?.default_location_id)
                        }}>Submit Quote</button>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuoteRequest;