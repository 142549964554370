import React, { useState, useEffect } from 'react';
import HireHeader from './HireHeader';
import HireFooter from './HireFooter';
import { Link, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import Moment from 'moment';
import LoadingSpinner from '../shared/Loader';

function Orders() {
  const props = useParams();
  const modalRating = {
    size: 15,
    count: 5,
    color: "#757575",
    activeColor: "#f8b84e",
    value: 3,
    a11y: true,
    isHalf: false,
    emptyIcon: <i className="fa fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: rateValue => {
      console.log(`Example 2: rate value is ${rateValue}`);
    }
  }
  const [currentOrders, setCurrentOrders] = React.useState({});
  const [previousOrders, setPreviousOrders] = React.useState({});
  const [currentPaginationPages, setCurrentPaginationPages] = React.useState([]);
  const [previousPaginationPages, setPreviousPaginationPages] = React.useState([]);
  const [orderIndex, setOrderIndex] = React.useState(0);
  const [resultMessage, setResultMessage] = React.useState('');
  const [cancelMessage, setCancelMessage] = React.useState('');
  const [ratingMessage, setRatingMessage] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const [quoteId, setQuoteId] = useState("")
  const [defaultTab, setDefaultTab] = useState('current');
  const [expiredOrders, setExpiredOrders] = useState([]);
  const [uploadTranscriptId, setUploadTranscriptId] = useState("")
  const [expiredOrdersPagination, setExpiredOrdersPagination] = useState([]);
  const [isDecline, setIsDecline] = useState(false)
  const [activeTab, setActiveTab] = useState('Current Orders');
  const handleTabClick = (tab) => {
    setActiveTab(tab);

  };


  useEffect(() => {

    userCurrentOrder('user_current_orders', 1);
    userCurrentOrder('previous_orders', 1);
    userCurrentOrder('expired_declined_orders', 1);
    setOrderIndex(0);
    if (props.type === 'previous') {
      setDefaultTab('previous');
    }
  }, []);
  const [inputValues, setInputValue] = useState({
    abuse_reason: '',
    cancel_reason: '',
    rating: '',
    vendorRating: 0,
    equipmentRating: 0
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setInputValue({ ...inputValues, [name]: value });
  }
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };

  const generatePdfUrl = (id) => {
    setIsPdfGenerating(true)
    setQuoteId(id)
    axios.get(`${appConstant.API_URL}/bx_block_order_management/generate_order_summary_pdf?id=${id}`, params).then((res) => {
      const prdUrl = `${appConstant.API_URL}/${res.data.pdf_url}`
      const link = document.createElement('a');
      link.href = prdUrl;
      link.download = 'downloaded.pdf';
      link.click();
      setIsPdfGenerating(false)

    }).catch((e) => {
      console.log(e, "error")
      setIsPdfGenerating(false)
    })
  }



  const downloadTranscript = async (url, filename) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;

      // Extract filename from URL and decode
      const urlObject = new URL(url);
      const decodedFilename = decodeURI(urlObject.pathname.split('/').pop());

      link.setAttribute('download', decodedFilename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      // Handle error
    }
  };

  const getOrderSummary = async (order_id) => {
    try {
      const response = await axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/order_summary?id=${order_id}`, params);
      if (response.data.status === 200) {
        const url = response.data.data.attributes.upload_transcript;
        await downloadTranscript(url);
      }
    } catch (error) {
      // Handle error
    }
  };

  const orderSummarydata = (order_id) => {
    getOrderSummary(order_id);
  };

  const handleFileUpload = (event, order_id, quote_id, idx) => {
   
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('upload_transcript', file);
    formData.append('order_id', order_id);
    formData.append('quote_id', quote_id);
    setUploadTranscriptId(idx)
    axios.put(`${appConstant.API_URL}/bx_block_order_management/upload_transcript`, formData, params)
      .then(response => {
        if (response) {
          setUploadTranscriptId('')
        }
        userCurrentOrder('previous_orders', 1, 'previous');
      })
      .catch(error => {
        console.error(error);
        setResultMessage(error.response.data.message)

      });
  };
  const userCurrentOrder = (type, page, currentTab = '') => {

    setIsLoading(true);

    axios.get(`${appConstant.API_URL}/bx_block_order_management/orders/${type}/?page=${page}&limit=20`, params)
      .then(res => {
        setIsLoading(false);
        window.scrollTo(0, 0);
        if (res.data.status === 200) {
          if (type === 'user_current_orders') {
            if (page > 1 || currentTab === 'current') {
              setDefaultTab('current');
            }
            setCurrentOrders(res.data);
            const row = [];
            for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
              row.push(i);
            }
            setCurrentPaginationPages(row);
          } else if (type === 'previous_orders') {
            if (page > 1 || currentTab === 'previous') {
              setDefaultTab('previous');
            }
            setPreviousOrders(res.data);
            const row = [];
            for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
              row.push(i);
            }
            setPreviousPaginationPages(row);
          } else {
            if (page > 1 || currentTab === 'expired') {
              setDefaultTab('expired');
            }
            setExpiredOrders(res.data);
            const row = [];
            for (var i = 1; i <= parseInt(res.data.total_pages); i++) {
              row.push(i);
            }
            setExpiredOrdersPagination(row);
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  }
  const reportAbusePopup = (index) => {
    setOrderIndex(index);
  }
  const cancelOrderPopup = (index) => {
    setOrderIndex(index);
  }
  const reviewPopup = (index) => {
    setOrderIndex(index);
  }
  const reportAbuse = (index) => {
    setIsButtonLoading(true);
    const orderId = (previousOrders?.data.length > 0) ? previousOrders?.data[index]?.attributes?.id : '';
    const reason = inputValues.abuse_reason;
    axios.put(`${appConstant.API_URL}/bx_block_order_management/orders/report_issue?id=${orderId}&reason=${reason}`, '', params)
      .then(res => {
        setIsButtonLoading(false);
        if (res.data.status === 200) {
          setResultMessage(res.data.message);
        } else {
          setResultMessage(res.data.message);
        }
        const obj = {
          abuse_reason: '',
          cancel_reason: '',
          rating: '',
          vendorRating: 0,
          equipmentRating: 0
        }
        setInputValue(obj);
        setTimeout(() => {
          setResultMessage('');
        }, 3000);
        window.location.reload();
      })
      .catch(error => {
        setIsButtonLoading(false);
      });
  }
  const cancelOrder = (index, page) => {
    setIsButtonLoading(true);
    const orderId = (previousOrders?.data.length > 0) ? previousOrders?.data[index]?.attributes?.id : '';
    const cancelOrderData = { reason: inputValues.cancel_reason }
    axios.put(`${appConstant.API_URL}/bx_block_order_management/orders/${orderId}/cancel_order`, cancelOrderData, params)
      .then(res => {
        setIsButtonLoading(false);
        if (res.data.status === 200) {
          setCancelMessage(res.data.message);
          userCurrentOrder('previous_orders', page, 'previous');
        } else {
          setCancelMessage(res.data.message);
        }
        const obj = {
          abuse_reason: '',
          cancel_reason: '',
          rating: '',
          vendorRating: 0,
          equipmentRating: 0
        }
        setInputValue(obj);
        setTimeout(() => {
          setCancelMessage('');
        }, 3000);
        window.location.reload()
      })
      .catch(error => {
        setIsButtonLoading(false);
      });
  }
  const vendorRating = (newRating) => {
    const data = inputValues;
    data.vendorRating = newRating;
    setInputValue(data);
  }
  const equipmentRating = (newRating) => {
    const data = inputValues;
    data.equipmentRating = newRating;
    setInputValue(data);
  }
  const orderReview = (index, page) => {
    setIsButtonLoading(true);
    const orderId = (previousOrders?.data.length > 0) ? previousOrders?.data[index]?.attributes?.id : '';
    const reviewOrderData = {
      seller_rating: inputValues.vendorRating,
      equipment_rating: inputValues.equipmentRating,
      client_review: inputValues.rating
    }
    axios.post(`${appConstant.API_URL}/bx_block_reviews/reviews/create_review?order_id=${orderId}`, reviewOrderData, params)
      .then(res => {
        setIsButtonLoading(false);
        if (res.data.status === 200) {
          window.location.reload();
          setRatingMessage(res.data.message);
          userCurrentOrder('previous_orders', page, 'previous');
        } else {
          setRatingMessage(res.data.message);
        }
        const obj = {
          abuse_reason: '',
          cancel_reason: '',
          rating: '',
          vendorRating: 0,
          equipmentRating: 0
        }
        setInputValue(obj);
        setTimeout(() => {
          setRatingMessage('');
        }, 3000);
      })
      .catch(error => {
        setIsButtonLoading(false);
      });
  }

  return (
    <>
      <HireHeader />
      <div className='filter-blank'></div>

      <div className='orders home-content'>
        <div className='container'>
          <div className='title' data-testid="orders">Orders / <span style={{ color: '--var(--tableHead)' }}>{activeTab}</span></div>
          <ul className="nav nav-pills alyya-tabs">
            <li className="nav-item">
              <a className={defaultTab === 'current' ? 'nav-link active' : 'nav-link'} data-bs-toggle="pill" href="#current-orders" onClick={() => handleTabClick('Current Orders')}>Current Orders</a>
            </li>
            <li className="nav-item">
              <a className={defaultTab === 'previous' ? 'nav-link active' : 'nav-link'} data-bs-toggle="pill" href="#previous-orders" onClick={() => handleTabClick('Previous Orders')}>Previous Orders</a>
            </li>
            <li className="nav-item">
              <a className={defaultTab === 'expired' ? 'nav-link active' : 'nav-link'} data-bs-toggle="pill" href="#expired-orders" onClick={() => handleTabClick('Expired/Declined')}>Expired / Declined</a>
            </li>
          </ul>
          <div className="tab-content alyya-tabs-content">
            {isLoading ? <LoadingSpinner /> :
              <>
                <div className={defaultTab === 'current' ? 'tab-pane container active' : 'tab-pane container'} id="current-orders">
                  <div className="table-responsive alyya-table">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th className='min-300'>Equipment</th>
                          <th className='min-210'>Type / Capacity</th>
                          <th className='min-250'>Duration</th>
                          <th className='min-180 text-center'>Quantity</th>
                          <th className='min-180 text-center'>Quotes/proposals</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrders?.data?.length > 0 && currentOrders?.data?.map((result, i) =>
                          <>
                            <tr key={i}>
                              <td className='min-300'>
                                <div className='d-flex align-items-center'>
                                  <img src={result?.attributes?.order_dtl?.attributes && result?.attributes?.order_dtl?.attributes?.image} className="" alt={result?.attributes?.order_dtl?.attributes?.equipment_name} />
                                  <div>
                                    <Link to={"/home/orders/current-orders/" + result?.id}><strong className='d-block'>{result?.attributes?.order_dtl?.attributes?.equipment_name}</strong></Link>
                                    <span className='d-block mt-2'>{result?.attributes?.order_dtl?.attributes?.model}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='min-210'><strong className='d-block'>{result?.attributes?.order_dtl?.attributes?.sub_category_name}</strong>
                                <span className='d-block mt-2'>{result?.attributes?.order_dtl?.attributes?.capacity} Capacity</span>
                              </td>
                              <td className='text-start  min-300' width={'250px'}>
                                <div className='row m-0 p-0'>
                                  <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                                  <div className='col-6 px-0'><strong>{Moment(result?.attributes?.order_dtl?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                  <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                                  <div className='col-6 px-0'><strong>{Moment(result?.attributes?.order_dtl?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                </div>
                              </td>
                              <td className='min-180 text-center'><strong>{result?.attributes?.order_dtl?.attributes?.quantity}</strong></td>
                              {result?.attributes?.quote_expired === true && <td className='min-180 text-center'><button className='table-red-button' type="button">Expired</button></td>}
                              {result?.attributes?.quote_expired === false && <td className='min-180 text-center'><Link to={"/home/orders/current-orders/" + result?.id} className='table-black-button' type="button">{result?.attributes?.quotes_count} received</Link></td>}
                            </tr>
                          </>
                        )}
                        {currentOrders?.data?.length === 0 && <tr><td colSpan='4'>Not Found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                  {currentOrders?.data?.length > 0 &&
                    <ul className="pagination justify-content-between alyya-pagination">
                      {currentOrders?.current_page === 1 &&
                        <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a>
                        </li>}
                      {currentOrders?.current_page !== 1 && <li className="page-item" onClick={() => {
                        userCurrentOrder('user_current_orders', currentOrders?.current_page - 1)
                      }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                      <div className='d-flex'>
                        {currentPaginationPages?.length > 0 && currentPaginationPages?.map((result, i) =>
                          <>
                            {result === currentOrders.current_page &&
                              <li className="page-item"><a className="page-link active">{result}</a></li>}
                            {result !== currentOrders.current_page &&
                              <li className="page-item"><a className="page-link" onClick={() => {
                                userCurrentOrder('user_current_orders', result)
                              }}>{result}</a></li>}

                          </>
                        )}
                      </div>

                      {currentOrders?.current_page === currentOrders?.total_pages &&
                        <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                      {currentOrders?.current_page !== currentOrders?.total_pages &&
                        <li className="page-item" onClick={() => {
                          userCurrentOrder('user_current_orders', currentOrders?.current_page + 1)
                        }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                    </ul>
                  }
                </div>
                <div className={defaultTab === 'previous' ? 'tab-pane container active' : 'tab-pane container'} id="previous-orders">
                  <div className="table-responsive alyya-table border-0">
                    <table className="table table-borderless">
                      <tbody>

                        {previousOrders?.data?.length > 0 && previousOrders?.data?.map((result, i) =>

                          <>
                            <tr className='border-0' key={i}>
                              <td colSpan="4" className="combined">
                                <div className="alyya-table  mb-0">
                                  <table className="table table-borderless">
                                    <tbody>
                                      <tr>
                                        <td className='min-250'>
                                          <div className='d-flex align-items-center'>
                                            <img src={result?.attributes?.order_equipments?.data?.attributes?.image} className="" alt={result?.attributes?.order_equipments?.data?.attributes?.product_name} />
                                            <div>
                                              <strong className='d-block'>{result?.attributes?.order_equipments?.data?.attributes?.product_name}</strong>
                                            </div>
                                          </div>
                                        </td>
                                        <td className='text-start min-300' width={'250px'}>
                                          <div className='row m-0 p-0'>
                                            <div className='col-6 px-0'><span className='mb-2'>Type</span></div>
                                            <div className='col-6 px-0'><strong>{result?.attributes?.order_equipments?.data?.attributes?.type}</strong></div>
                                            <div className='col-6 px-0'><span className='mb-2'>Capacity</span></div>
                                            <div className='col-6 px-0'><strong>{result?.attributes?.order_equipments?.data?.attributes?.capacity.value}</strong></div>
                                            <div className='col-6 px-0'><span className='mb-2'>Model</span></div>
                                            <div className='col-6 px-0'><strong>{result?.attributes?.order_equipments?.data?.attributes?.model}</strong></div>
                                          </div>
                                        </td>
                                        <td className='text-start  min-300' width={'250px'}>
                                          <div className='row m-0 p-0'>
                                            <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                                            <div className='col-6 px-0'><strong>{Moment(result?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                            <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                                            <div className='col-6 px-0'><strong>{Moment(result?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                            <div className='col-6 px-0'><span className='mb-2'>Quantity</span></div>
                                            <div className='col-6 px-0'><strong>{result?.attributes?.quantity}</strong></div>

                                          </div>
                                        </td>
                                        <td className='min-180 text-end'>
                                          <div className="download-buttons">

                                            {result?.attributes?.upload_transcript && <span className='links d-block mb-2 pb-3' onClick={() => generatePdfUrl(result.attributes.id)} data-testid="download-invoice"> <a className='generatePDf-loader'> {isPdfGenerating && quoteId === result.attributes.id ? <LoadingSpinner /> : <img className='invoice' style={{ width: '25px', height: '21px' }} src="/images/invoice.png" alt="invoice" />}&nbsp;&nbsp; {isPdfGenerating && quoteId === result.attributes.id ? "generating pdf..." : "Download Invoice"}</a></span>}
                                            {result?.attributes?.upload_transcript && <a href={result.attributes.upload_transcript}> <span className='links d-block mb-2' style={{ cursor: 'pointer' }} onClick={() => orderSummarydata(result?.attributes?.quote_id)}><img className='invoice' style={{ width: '25px', height: '21px' }} src="/images/invoice-icon.png" alt="invoice" /> &nbsp;&nbsp;Download Transcript</span></a>}
                                          </div>
                                          {result?.attributes?.status === 'cancelled' ? (result?.attributes?.upload_transcript && (<button className='orange-button' style={{ visibility: 'hidden', pointerEvents: 'none' }} type="button"> Upload Transcript </button>)) : (!result?.attributes?.upload_transcript && (<div> <label htmlFor={`fileInput${i}`} className='orange-button' data-bs-toggle="modal" data-bs-target="#sendQuotes" style={{ color: 'white', cursor: 'pointer' }} data-testid="upload-transcript"> Upload Transcript</label> <input id={`fileInput${i}`} type="file" style={{ display: 'none' }} onChange={(event) => handleFileUpload(event, result?.attributes?.id, result?.attributes?.quote_id, i)} /> </div>))}
                                          {resultMessage && uploadTranscriptId === i && <p className='formErrors text-center'>{resultMessage}</p>}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={4} className="px-0">
                                          <table className="table table-borderless">
                                            <tbody>
                                              <tr>
                                                <td className='min-210'>
                                                  <span className='d-block'>Order Number <strong>{result?.attributes?.order_number}</strong></span>
                                                  <span className='d-block mt-2'>Ordered Date <strong>{Moment(result?.attributes?.ordered_date).format('DD-MM-YYYY')} at {Moment(result?.attributes?.ordered_date).format('hh:mm A')}</strong></span>
                                                </td>
                                                <td className='min-250'>
                                                  <div className='d-flex align-items-center'>
                                                    <img src={result?.attributes?.order_equipments?.data?.attributes?.vendor?.image ? result?.attributes?.order_equipments?.data?.attributes?.vendor?.image : '/images/login_banner.png'} className="order-image-2" alt="equipment" />
                                                    <div>
                                                      <strong className='d-block mb-2'>{result?.attributes?.order_equipments?.data?.attributes?.vendor?.full_name}</strong>
                                                      <span>({result?.attributes?.equipment_total_reviews < 2 ? result?.attributes?.equipment_total_reviews + ' review' : result?.attributes?.equipment_total_reviews + ' reviews'})</span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td className='min-250 text-center'>
                                                  <span className='mb-2'>Total Cost</span>

                                                  <strong className='d-block'>
                                                    <div className='d-inline-block mx-2'>
                                                      SAR
                                                    </div>
                                                    {result?.attributes?.total_cost !== undefined
                                                      ? Number(result?.attributes?.total_cost).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }).replace(/^(\D+)/, '')
                                                      : 0
                                                    }
                                                  </strong>
                                                </td>
                                                <td className='min-180 text-end'>
                                                  <Link to={"/home/orders/previous-orders/order-summary/" + result?.attributes?.id} className='order-summary-2'>order Summary</Link>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={4} className="px-0">
                                          <table className="table table-borderless">
                                            <tbody>
                                              <tr>
                                                <td className='min-250 text-start'>
                                                  <div className='d-flex'>
                                                    {result?.attributes?.review_now ? <button className='rate-equipment' type="button" data-bs-toggle="modal" onClick={() => { reviewPopup(i) }} data-bs-target="#rate">Rate &nbsp;</button> : <button className='rate-equipment' style={{ visibility: 'hidden' }} type="button" data-bs-toggle="modal" onClick={() => { reviewPopup(i) }} data-bs-target="#rate">Rate &nbsp;</button>}
                                                    <ReactStars size={15} value={Math.round(result?.attributes?.equipment_rating)} count={5} color={'#757575'} a11y={false} activeColor={'#f8b84e'} half={true} edit={false}
                                                      emptyIcon={<i className="fa fa-star" />} halfIcon={<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />} />
                                                  </div>
                                                </td>
                                                <td className='min-250'>
                                                  Order Status <span className={result?.attributes?.status === 'cancelled' ? 'rejected' : (result?.attributes?.status === 'confirmed' ? 'success' : 'warning')}>{result?.attributes?.status.toUpperCase()}</span>
                                                </td>
                                                <td className='min-250'>
                                                  Payment Status <span className='payment-status' style={{ textTransform: 'capitalize' }}>{result?.attributes?.payment_status}</span>
                                                </td>
                                                <td className='min-330 text-end'>
                                                  <button className='report-issue' type="button" data-bs-toggle="modal" data-bs-target="#report" onClick={() => { reportAbusePopup(i, result?.attributes?.id) }} disabled={true}>Report Issue</button>
                                                  {(result?.attributes?.status === 'cancelled' || result?.attributes?.payment_status === "PAID") ? <button className='cancel-order' style={{ display: "none" }} type="button" data-bs-toggle="modal" data-bs-target="#cancel" disabled={true}>Cancel Order</button> : <button className='cancel-order' type="button" data-bs-toggle="modal" data-bs-target="#cancel" onClick={() => { cancelOrderPopup(i, result?.attributes?.id) }}>Cancel Order</button>}

                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {previousOrders?.data?.length > 0 &&
                    <ul className="pagination justify-content-between alyya-pagination">
                      {previousOrders?.current_page === 1 &&
                        <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a>
                        </li>}
                      {previousOrders?.current_page !== 1 && <li className="page-item" onClick={() => {
                        userCurrentOrder('previous_orders', previousOrders?.current_page - 1)
                      }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                      <div className='d-flex'>
                        {previousPaginationPages?.length > 0 && previousPaginationPages?.map((result, i) =>
                          <>
                            {result === previousOrders.current_page &&
                              <li className="page-item"><a className="page-link active">{result}</a></li>}
                            {result !== previousOrders.current_page &&
                              <li className="page-item"><a className="page-link" onClick={() => {
                                userCurrentOrder('previous_orders', result)
                              }}>{result}</a></li>}

                          </>
                        )}
                      </div>

                      {previousOrders?.current_page === previousOrders?.total_pages &&
                        <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                      {previousOrders?.current_page !== previousOrders?.total_pages &&
                        <li className="page-item" onClick={() => {
                          userCurrentOrder('previous_orders', previousOrders?.current_page + 1)
                        }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                    </ul>
                  }
                </div>
                <div className={defaultTab === 'expired' ? 'tab-pane container active' : 'tab-pane container'} id="expired-orders">
                  <div className="table-responsive alyya-table">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th className='min-300'>Equipment</th>
                          <th className='min-210'>Type / Capacity</th>
                          <th className='min-250'>Duration</th>
                          <th className='min-180 text-center'>Quantity</th>
                          <th className='min-180 text-center'>Quotes/proposals</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expiredOrders?.data?.length > 0 && expiredOrders?.data?.map((result, i) =>
                          <>
                            <tr key={i}>
                              <td className='min-300'>
                                <div className='d-flex align-items-center'>
                                  <img src={result?.attributes?.order_dtl?.attributes && result?.attributes?.order_dtl?.attributes?.image} className="" alt={result?.attributes?.order_dtl?.attributes?.equipment_name} />
                                  <div>
                                    <Link to={"/home/orders/current-orders/" + result?.id}><strong className='d-block'>{result?.attributes?.order_dtl?.attributes?.equipment_name}</strong></Link>
                                    <span className='d-block mt-2'>{result?.attributes?.order_dtl?.attributes?.model}</span>
                                  </div>
                                </div>
                              </td>
                              <td className='min-210'><strong className='d-block'>{result?.attributes?.order_dtl?.attributes?.sub_category_name}</strong>
                                <span className='d-block mt-2'>{result?.attributes?.order_dtl?.attributes?.capacity} Capacity</span>
                              </td>
                              <td className='text-start  min-300' width={'250px'}>
                                <div className='row m-0 p-0'>
                                  <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                                  <div className='col-6 px-0'><strong>{Moment(result?.attributes?.order_dtl?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                                  <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                                  <div className='col-6 px-0'><strong>{Moment(result?.attributes?.order_dtl?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                                </div>
                              </td>
                              <td className='min-180 text-center'><strong>{result?.attributes?.order_dtl?.attributes?.quantity}</strong></td>
                              {result?.attributes?.quote_expired === true && <td className='min-180 text-center'><button className='table-red-button' type="button">Expired</button></td>}
                              {result?.attributes?.quote_declined === true && <td className='min-180 text-center'><button className='table-red-button' type="button">Declined</button> </td>}
                            </tr>
                          </>
                        )}
                        {expiredOrders?.data?.length === 0 && <tr><td colSpan='4'>Not Found</td></tr>}
                      </tbody>
                    </table>
                  </div>
                  {expiredOrders?.data?.length > 0 &&
                    <ul className="pagination justify-content-between alyya-pagination">
                      {expiredOrders?.current_page === 1 &&
                        <li className="page-item disabled"><a className="page-link">&#129120; &nbsp; Previous</a>
                        </li>}
                      {expiredOrders?.current_page !== 1 && <li className="page-item" onClick={() => {
                        userCurrentOrder('expired_declined_orders', expiredOrders?.current_page - 1)
                      }}><a className="page-link">&#129120; &nbsp; Previous</a></li>}
                      <div className='d-flex'>
                        {expiredOrdersPagination?.length > 0 && expiredOrdersPagination?.map((result, i) =>
                          <>
                            {result === expiredOrders.current_page &&
                              <li className="page-item"><a className="page-link active">{result}</a></li>}
                            {result !== expiredOrders.current_page &&
                              <li className="page-item"><a className="page-link" onClick={() => {
                                userCurrentOrder('expired_declined_orders', result)
                              }}>{result}</a></li>}

                          </>
                        )}
                      </div>

                      {expiredOrders?.current_page === expiredOrders?.total_pages &&
                        <li className="page-item disabled"><a className="page-link">Next &nbsp; &#129122;</a></li>}
                      {expiredOrders?.current_page !== expiredOrders?.total_pages &&
                        <li className="page-item" onClick={() => {
                          userCurrentOrder('expired_declined_orders', expiredOrders?.current_page + 1)
                        }}><a className="page-link">Next &nbsp; &#129122;</a></li>}
                    </ul>
                  }
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <HireFooter />
      {/* modal */}
      {/* rate modal  */}
      <div className="modal back-shadow" id="rate">
        <div className="modal-dialog modal-dialog-centered modal-width">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Rate Your Order</h4>
              <button type="button" className="close-button" data-bs-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <div className="table-responsive alyya-table mb-5">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className='min-300'>Equipment & Capacity</th>
                      <th className='min-210'>Category/Capacity</th>
                      <th className='min-210 text-center'>Duration</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='min-300'>
                        <div className='d-flex align-items-center'>
                          <img src={(previousOrders?.data?.length > 0 && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes) ? previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.image : '/images/login_banner.png'} className="" alt={'image'} />
                          <div>
                            <strong className='d-block'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.product_name}</strong>
                          </div>
                        </div>
                      </td>
                      <td className='min-210'>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.sub_category_name}</span>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.capacity.value} Capacity</span >
                      </td>
                      <td className='text-start  min-300' width={'250px'}>
                        <div className='row m-0 p-0'>
                          <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                          <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                        </div>
                      </td>
                      <td>
                        <strong>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.quantity} </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='title mb-3'>Rate your equipment.</div>
              <ReactStars size={15} value={Math.round(inputValues.equipmentRating)} count={5} color={'#757575'} a11y={false} activeColor={'#f8b84e'} half={true} edit={true}
                emptyIcon={<i className="fa fa-star" />} halfIcon={<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />} onChange={equipmentRating} />
              <div className='title my-3'>Rate your experience with {(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.organisation_name}.</div>
              <ReactStars size={15} value={Math.round(inputValues.vendorRating)} count={5} color={'#757575'} a11y={false} activeColor={'#f8b84e'} half={true} edit={true}
                emptyIcon={<i className="fa fa-star" />} halfIcon={<i className="fa fa-star-half-alt" />} filledIcon={<i className="fa fa-star" />} onChange={vendorRating} />
              <div className='title mt-3 mb-2'>Write a review or tell us about your overall experience.</div>
              <form className='alyya-form'>
                <textarea name="rating" value={inputValues.rating} onChange={(e) => handleChange(e)} className='textarea-with-border' rows="5" placeholder='Tell us about your overall experience.  '></textarea>
                <button type="button" onClick={() => { orderReview(orderIndex, previousOrders?.current_page) }} className='submit-buttons w-auto mx-auto mt-3 d-block'>Submit Review</button>
                {ratingMessage && <p className='formErrors text-center'>{ratingMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* report issue modal  */}
      <div className="modal back-shadow" id="report">
        <div className="modal-dialog modal-dialog-centered modal-width">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Report Issue</h4>
              <button type="button" className="close-button" data-bs-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <div className="table-responsive alyya-table mb-4">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className='min-300'>Equipment & Capacity</th>
                      <th className='min-210'>Category/Capacity</th>
                      <th className='min-210 text-center'>Duration</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='min-300'>
                        <div className='d-flex align-items-center'>
                          <img src={(previousOrders?.data?.length > 0 && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes) ? previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.image : '/images/login_banner.png'} className="" alt={'image'} />
                          <div>
                            <strong className='d-block'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.product_name}</strong>
                          </div>
                        </div>
                      </td>
                      <td className='min-210'>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.sub_category_name}</span>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.capacity.value} Capacity</span >
                      </td>
                      <td className='text-start  min-300' width={'250px'}>
                        <div className='row m-0 p-0'>
                          <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                          <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                        </div>
                      </td>
                      <td>
                        <strong>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.quantity}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='title mb-2'>Describe your issue with the order</div>
              <form className='alyya-form'>
                <textarea name="abuse_reason" value={inputValues.abuse_reason} onChange={(e) => handleChange(e)} className='textarea-with-border' rows="5"></textarea>
                {isButtonLoading ? <div className='position-relative'><button className="submit-buttons w-auto mx-auto mt-3 d-block" disabled={true} type="button" >Report Issue</button> <img className='position-absolute' style={{ "top": "32%", "right": "43%" }} src='/images/default.gif' width={'16px'} height={'16px'} /></div> :
                  <button type="button" className='submit-buttons w-auto mx-auto mt-3 d-block' onClick={() => reportAbuse(orderIndex)} disabled={true}>Report Issue</button>
                }
                {resultMessage && <p className='formErrors text-center'>{resultMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* cancel order modal  */}
      <div className="modal back-shadow" id="cancel">
        <div className="modal-dialog modal-dialog-centered modal-width">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Cancel Order</h4>
              <button type="button" className="close-button" data-bs-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <div className="table-responsive alyya-table mb-4">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th className='min-300'>Equipment & Capacity</th>
                      <th className='min-210'>Category/Capacity</th>
                      <th className='min-210 text-center'>Duration</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='min-300'>
                        <div className='d-flex align-items-center'>
                          <img src={(previousOrders?.data?.length > 0 && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes) ? previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.image : '/images/login_banner.png'} className="" alt={'image'} />
                          <div>
                            <strong className='d-block'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.product_name}</strong>
                          </div>
                        </div>
                      </td>
                      <td className='min-210'>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.sub_category_name}</span>
                        <span className='d-block mt-2'>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.order_equipments?.data?.attributes?.capacity.value} Capacity</span >
                      </td>
                      <td className='text-start  min-300' width={'250px'}>
                        <div className='row m-0 p-0'>
                          <div className='col-6 px-0'><span className='mb-2'>Start Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.start_date).format('DD-MM-YYYY')}</strong></div>
                          <div className='col-6 px-0'><span className='mb-2'>End Date</span></div>
                          <div className='col-6 px-0'><strong>{(previousOrders?.data?.length > 0) && Moment(previousOrders?.data[orderIndex]?.attributes?.end_date).format('DD-MM-YYYY')}</strong></div>
                        </div>
                      </td>
                      <td>
                        <strong>{(previousOrders?.data?.length > 0) && previousOrders?.data[orderIndex]?.attributes?.quantity}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='title mb-2'>Reason to cancel order</div>
              <form className='alyya-form'>
                <textarea name="cancel_reason" value={inputValues.cancel_reason} onChange={(e) => handleChange(e)} className='textarea-with-border' rows="5"></textarea>

                {isButtonLoading ? <div className='position-relative'><button className="submit-buttons w-auto mx-auto mt-3 d-block" disabled={true} type="button">Cancel Order</button> <img className='position-absolute' style={{ "top": "32%", "right": "43%" }} src='/images/default.gif' width={'16px'} height={'16px'} /></div> :
                  <button type="button" className='submit-buttons w-auto mx-auto mt-3 d-block' onClick={() => cancelOrder(orderIndex, previousOrders?.current_page)}>Cancel Order</button>
                }
                {cancelMessage && <p className='formErrors text-center mt-3'>{cancelMessage}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Orders;